<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Job Details
          </h3>
        </div>
        <div>
          <div class="grid grid-cols-3 gap-6">
            <div class="col-span-6">
              <label
                for="jobTitle"
                class="block text-sm font-medium text-gray-700"
                >What is the job title of the role? *</label
              >
              <input
                :value="formData.jobTitle"
                type="text"
                name="jobTitle"
                id="input-job-title"
                autocomplete="jobTitle"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                :class="{
                  'bg-gray-200 border-none cursor-not-allowed':
                    showApprovalSteps,
                  'error-border': v$.formData.jobTitle.$error,
                  'border-red-500': v$.formData.jobTitle.$error,
                }"
                :disabled="showApprovalSteps"
                @input="handleInputChange"
                @blur="v$.formData.jobTitle.$touch()"
              />
              <span
                v-if="v$.formData.jobTitle.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.jobTitle.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <div class="col-span-6">
              <label
                for="numberOfPositions"
                class="block text-sm font-medium text-gray-700"
                >How many positions are you recruiting for? *</label
              >
              <input
                :value="formData.numberOfPositions"
                type="number"
                min="0"
                name="numberOfPositions"
                id="input-number-of-positions"
                autocomplete="numberOfPositions"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                :class="{
                  'bg-gray-200 border-none cursor-not-allowed':
                    showApprovalSteps,
                  'error-border': v$.formData.numberOfPositions.$error,
                  'border-red-500': v$.formData.numberOfPositions.$error,
                }"
                :disabled="showApprovalSteps"
                @input="handleInputChange"
                @blur="v$.formData.numberOfPositions.$touch()"
              />
              <span
                v-if="v$.formData.numberOfPositions.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.numberOfPositions.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <div class="col-span-6">
              <label
                for="roleLocationSite"
                class="block text-sm font-medium text-gray-700"
                >Role Location (Site): *</label
              >
              <select
                :value="formData.roleLocationSite"
                name="roleLocationSite"
                id="input-role-location-site"
                autocomplete="roleLocationSite"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                :class="{
                  'bg-gray-200 border-none cursor-not-allowed':
                    showApprovalSteps,
                  'error-border': v$.formData.roleLocationSite.$error,
                  'border-red-500': v$.formData.roleLocationSite.$error,
                }"
                :disabled="showApprovalSteps"
                @change="handleInputChange"
                @blur="v$.formData.roleLocationSite.$touch()"
              >
                <template v-if="showApprovalSteps">
                  <option
                    v-for="location in configData.LOCATIONS"
                    :key="location.LOCATIONCODE"
                    :value="`${location.OBJECTNAME} - ${location.LOCATIONCODE}`"
                  >
                    {{ location.DISPLAYNAME }}
                  </option>
                </template>
                <template v-else>
                  <option
                    v-for="location in activeLocations"
                    :key="location.LOCATIONCODE"
                    :value="`${location.OBJECTNAME} - ${location.LOCATIONCODE}`"
                  >
                    {{ location.DISPLAYNAME }}
                  </option>
                </template>
              </select>
              <span
                v-if="v$.formData.roleLocationSite.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.roleLocationSite.$errors[0].$message }}
              </span>
              <p class="mt-1 text-sm text-red-500">
                <b>
                  *Please note this is still required even if the employee is a
                  Mobile Worker.
                </b>
              </p>
            </div>
          </div>
          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <div class="col-span-6">
              <label
                for="roleLocationPostcode"
                class="block text-sm font-medium text-gray-700"
                >Role Location (Postcode):</label
              >
              <input
                disabled
                :value="formData.roleLocationPostcode"
                name="roleLocationPostcode"
                id="input-role-location-postcode"
                autocomplete="roleLocationPostcode"
                class="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-600"
                :class="{
                  'bg-gray-200 border-none cursor-not-allowed':
                    showApprovalSteps,
                }"
              />
              <p class="mt-1 text-sm text-red-500">
                <b>
                  *Please note this is still required even if the employee is a
                  Mobile Worker.
                </b>
              </p>
            </div>
          </div>
          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <fieldset>
              <legend class="text-base font-medium text-gray-900">
                What is the employment contract for this role? *
              </legend>
              <span
                v-if="v$.formData.employmentContract.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.employmentContract.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-employment-contract-apprentice"
                      name="employmentContract"
                      type="radio"
                      value="Apprentice"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :checked="formData.employmentContract === 'Apprentice'"
                      :disabled="showApprovalSteps"
                      @change="handleRadioChange"
                      @blur="v$.formData.employmentContract.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="employmentContract"
                      class="font-medium text-gray-700"
                      >Apprentice</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-employment-contract-fixed-term"
                      name="employmentContract"
                      type="radio"
                      value="Fixed Term"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.employmentContract === 'Fixed Term'"
                      @change="handleRadioChange"
                      @blur="v$.formData.employmentContract.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="employmentContract"
                      class="font-medium text-gray-700"
                      >Fixed Term</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-employment-contract-graduate"
                      name="employmentContract"
                      type="radio"
                      value="Graduate"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :checked="formData.employmentContract === 'Graduate'"
                      :disabled="showApprovalSteps"
                      @change="handleRadioChange"
                      @blur="v$.formData.employmentContract.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="employmentContract"
                      class="font-medium text-gray-700"
                      >Graduate</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-employment-contract-permanent"
                      name="employmentContract"
                      type="radio"
                      value="Permanent"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.employmentContract === 'Permanent'"
                      @change="handleRadioChange"
                      @blur="v$.formData.employmentContract.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="employmentContract"
                      class="font-medium text-gray-700"
                      >Permanent</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-employment-contract-secondment"
                      name="employmentContract"
                      type="radio"
                      value="Secondment"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.employmentContract === 'Secondment'"
                      @change="handleRadioChange"
                      @blur="v$.formData.employmentContract.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="employmentContract"
                      class="font-medium text-gray-700"
                      >Secondment</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-employment-contract-zero-hours"
                      name="employmentContract"
                      type="radio"
                      value="Zero Hours"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.employmentContract === 'Zero Hours'"
                      @change="handleRadioChange"
                      @blur="v$.formData.employmentContract.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="employmentContract"
                      class="font-medium text-gray-700"
                      >Zero Hours</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
            v-show="formData.employmentContract === 'Secondment'"
          >
            <fieldset>
              <legend class="text-base font-medium text-gray-900">
                As this is a secondment please specify if an allowance or the
                full salary should be shown *
              </legend>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-secondment"
                      name="secondment"
                      type="radio"
                      value="Allowance"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      :checked="formData.secondment === 'Allowance'"
                      @change="handleRadioChange"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="secondment" class="font-medium text-gray-700"
                      >Allowance</label
                    >
                  </div>
                </div>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-secondment"
                      name="secondment"
                      type="radio"
                      value="Full Salary"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      :checked="formData.secondment === 'Full Salary'"
                      @change="handleRadioChange"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="secondment" class="font-medium text-gray-700"
                      >Full Salary</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
            v-show="this.formShow"
          >
            <div class="col-span-6">
              <label
                for="duration"
                class="block text-sm font-medium text-gray-700"
                >What is the duration of the employment contract? *</label
              >
              <input
                :value="formData.duration"
                type="number"
                min="0"
                name="duration"
                id="input-duration"
                autocomplete="duration"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                :class="{
                  'error-border': v$.formData.duration.$error,
                  'border-red-500': v$.formData.duration.$error,
                }"
                @input="handleInputChange"
              />
              <p
                class="text-sm text-red-700"
                v-show="formData.employmentContract === 'Apprentice'"
              >
                For Apprentice, please complete and attach the Apprentice
                Details Form, a copy of the form is avaliable
                <a
                  href="https://equans.sharepoint.com/:w:/r/sites/ukbuems/_layouts/15/Doc.aspx?sourcedoc=%7B82D33E70-6205-41EF-805D-642F09CB3854%7D&file=EQUANS%20Apprentice%20Details%20Form.docx&action=default&mobileredirect=true"
                  target="_blank"
                  class="text-blue-600 hover:underline"
                  >here</a
                >
              </p>
              <p class="text-sm text-red-700" v-show="formShow">
                Please enter a numerical value in Months
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!showApprovalSteps"
      class="px-4 py-3 bg-gray-50 text-right sm:px-6"
    >
      <button
        class="mr-2 bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        @click.prevent="handleResetForm"
      >
        Reset Form
      </button>
      <button
        :disabled="v$.$invalid"
        type="submit"
        class="bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400"
        @click="handleSave"
      >
        Next
      </button>
    </div>
  </form>
</template>

<script>
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { helpers, required, numeric, requiredIf } from "@vuelidate/validators";
// Custom validator for number field
const mustBeGreaterThanZero = (value) => value > 0;
export default {
  name: "FormThree",
  props: ["formData", "configData", "showApprovalSteps"],
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData,
    };
  },
  methods: {
    handleSave(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submit-form", {
          name: "formThree",
          formData: this.formData,
        });
      } else {
        this.store.dispatch("setMessage", {
          type: "error",
          title: "ERROR!",
          message: "Please fill out all the required fields",
        });
      }
    },
    handleInputChange(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit("update:formData", {
        formName: "formThree",
        formData: this.model,
      });
    },
    handleRadioChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.model[name] = value;
      this.$emit("update:formData", {
        formName: "formThree",
        formData: this.model,
      });
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit("update:reset", "formThree");
    },
  },
  computed: {
    activeLocations() {
      return this.configData.LOCATIONS.filter((loc) => loc.LKStatus);
    },
    formShow() {
      if (
        this.formData.employmentContract === "Fixed Term" ||
        this.formData.employmentContract === "Zero Hours" ||
        this.formData.employmentContract === "Apprentice" ||
        this.formData.employmentContract === "Secondment"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    "model.roleLocationSite"(newLocation) {
      const location = this.configData.LOCATIONS.find(
        (loc) => `${loc.OBJECTNAME} - ${loc.LOCATIONCODE}` === newLocation
      );
      this.model.roleLocationPostcode = location ? location.POSTCODE : "";
      this.$emit("update:formData", {
        formName: "formThree",
        formData: this.model,
      });
    },
    formData(newVal) {
      this.model = newVal;
    },
  },
  validations() {
    return {
      formData: {
        jobTitle: {
          required,
        },
        numberOfPositions: {
          required,
          numeric,
          mustBeGreaterThanZero: helpers.withMessage(
            "Value must be greater than 0",
            mustBeGreaterThanZero
          ),
        },
        roleLocationSite: {
          required,
        },
        employmentContract: {
          required,
        },
        secondment: {
          requiredIf: helpers.withMessage(
            "Value is required",
            requiredIf(this.formData.employmentContract === "Secondment")
          ),
        },
        duration: {
          requiredIf: helpers.withMessage(
            "Value is required",
            requiredIf(this.formShow)
          ),
        },
      },
    };
  },
};
</script>

<style scoped>
.error-border {
  border: 2px solid red;
}
</style>
