import { saveStorage } from "./storageSave";
import httpClient from "./httpClient";
const blankForm = {
  formOne: {
    hiringManagerName: "",
    hiringManagerEmail: "",
    employeeName: "",
    employeeEmail: "",
    saved: false,
  },
  formTwo: {
    recruitmentReason: "",
    inBudget: "",
    saved: false,
  },
  formThree: {
    jobTitle: "",
    numberOfPositions: "",
    roleLocationSite: "",
    roleLocationPostcode: "",
    employeeType: "",
    employmentContract: "",
    saved: false,
  },
  formFour: {
    businesscontact: null,
    employedin: "",
    division: "",
    assigned: "",
    saved: false,
  },
  formFive: {
    hoursPerWeek: "",
    weeksPerYear: "",
    shiftPattern: [],
    additionalInformation: "",
    saved: false,
  },
  formSix: {
    contractType: "",
    contractLevel: "",
    salaryBasis: "",
    minimumPayRate: "",
    minimumPayRateCurrency: "GBP",
    maximumPayRate: "",
    maximumPayRateCurrency: "GBP",
    additionalAllowances: "",
    additionalAllowancesInformation: [
      {
        additionalAllowancesTitle: "",
        additionalAllowancesInformation: "",
      },
    ],
    payrollFrequency: "",
    saved: false,
  },
  formSeven: {
    recruitmentTeam: "",
    advertise: "selected",
    channels: [],
    channelsOther: "",
    recruitmentShortlist: "",
    saved: false,
  },
  formEight: {
    discloseCheckRequired: "",
    disclosureSchemeEnglandWales: "",
    disclosureSchemeScotland: "",
    childSafeguardingRequired: "",
    saved: false,
  },
  formNine: {
    securityRole: "",
    securitySIA: "",
    securityCheck: "",
    securityPreliminary: "",
    securityBSVR: "",
    securityGovernment: "",
    securityDWP: "",
    securityMobile: "",
    securityBefore: "",
    saved: false,
  },
  formTen: {
    highwayWorker: "",
    mobileWorker: "",
    hybridWorker: "",
    collectiveBargaining: "",
    collectiveBargainingText: "",
    saved: false,
  },
  formEleven: {
    files: [],
    saved: false,
  },
  formTwelve: {
    firstLineApproval: "",
    saved: false,
  },
  status: "DRAFT",
};

// Reset the formData object in local storage
const resetForm = function () {
  saveStorage("formData", blankForm);
  return "form reset";
};

// Save the current form
const saveFormStep = async function (formName, data, requestedBy, id) {
  try {
    // Make the api appropriate API request for the form being submitted
    const payload = { formData: { ...data }, requestedBy, id };
    const response = await httpClient.post(`/bff/starter/${formName}`, payload);
    const body = response.data.body;
    saveStorage("shortId", body.Item.shortId);
    saveStorage("atrId", body.Item.docId);

    saveStorage("visible", true);
    saveStorage("type", "success");
    saveStorage("title", "SAVED!");
    saveStorage("message", "The form has been saved");
  } catch (err) {
    saveStorage("visible", true);
    saveStorage("type", "error");
    saveStorage("title", "ERROR!");
    saveStorage("message", "There was an error submitting the form.");
  }
};

const completeAtr = function (payload) {
  try {
    httpClient.post("/bff/starter/complete", payload);
    saveStorage("visible", true);
    saveStorage("type", "success");
    saveStorage("title", "SAVED!");
    saveStorage("message", "Your ATR has been submitted");
  } catch (err) {
    saveStorage("visible", true);
    saveStorage("type", "error");
    saveStorage("title", "ERROR!");
    saveStorage("message", "There was an error submitting your ATR form.");
  }
};

const getChangedValues = function (oldForm, newForm) {
  let changedValues = [];
  // find any updated values
  for (const key of Object.keys(oldForm)) {
    for (const key2 of Object.keys(oldForm[key])) {
      if (oldForm[key][key2]?.length && !newForm[key][key2]?.length) {
        changedValues.push({
          [key]: {
            [key2]: { old: oldForm[key][key2], new: newForm[key][key2] },
          },
        });
      } else if (
        key2.length > 3 &&
        oldForm[key][key2] !== newForm[key][key2] &&
        oldForm[key][key2].length &&
        newForm[key][key2].length
      ) {
        let changed = false;

        if (typeof oldForm[key][key2] !== "object") {
          changedValues.push({
            [key]: {
              [key2]: { old: oldForm[key][key2], new: newForm[key][key2] },
            },
          });
        } else {
          // array values changed
          if (oldForm[key][key2].length !== newForm[key][key2].length) {
            changed = true;
          } else {
            for (const key3 of Object.keys(oldForm[key][key2])) {
              if (
                JSON.stringify(oldForm[key][key2][key3]) !==
                JSON.stringify(newForm[key][key2][key3])
              ) {
                changed = true;
              }
            }
          }
          if (changed) {
            changedValues.push({
              [key]: {
                [key2]: { old: oldForm[key][key2], new: newForm[key][key2] },
              },
            });
          }
        }
      } else if (key2.length > 3 && !newForm[key][key2] && oldForm[key][key2]) {
        // fields removed
        changedValues.push({
          [key]: {
            [key2]: { old: oldForm[key][key2], new: newForm[key][key2] },
          },
        });
      }
    }
  }
  // any new fields addded
  for (const key of Object.keys(newForm)) {
    for (const key2 of Object.keys(newForm[key])) {
      if (key2.length > 3 && !oldForm[key][key2] && newForm[key][key2]) {
        changedValues.push({
          [key]: {
            [key2]: { old: oldForm[key][key2], new: newForm[key][key2] },
          },
        });
      }
    }
  }

  return changedValues;
};

export { resetForm, saveFormStep, completeAtr, getChangedValues };
