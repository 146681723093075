<template>
  <div class="m-4 px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Resourcing Dashboard</h1>
      </div>
    </div>
    <div class="mt-8 flex flex-col md:flex-row justify-end">
      <div class="mt-2 grow">
        <label for="search">Search:</label>
        <input
          v-model="searchTerm"
          type="text"
          name="search"
          id="search-filter"
          class="
            w-full
            mt-1
            focus:ring-indigo-500 focus:border-indigo-500
            block
            shadow-sm
            sm:text-sm
            border-gray-300
            rounded-md
          "
        >
      </div>
      <div class="mt-2 md:ml-2">
        <label for="filter-doctype" class="text-gray-600">Stage:</label>
        <select
          v-model="stage"
          name="filter-stage"
          id="filter-stage-select"
          class="
            w-full
            mt-1
            focus:ring-indigo-500 focus:border-indigo-500
            block
            shadow-sm
            sm:text-sm
            border-gray-300
            rounded-md
          "
        >
          <!-- Starter Options -->
          <option key="all" value="all">All ({{totalCount}})</option>
          <option
            v-for="stage in stageDropdownItems"
            :key="stage.stageName"
            :value="stage.stageName"
          >{{ stage.displayName }} ({{ findCount(stage.stageName)}})</option>
        </select>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 relative cursor-pointer"
                    v-for="heading in headings"
                    :key="heading"
                    @click="handleHeadingClick(heading)"
                  >
                    {{ heading }}
                    <span v-if="currentSort === heading" class="absolute">
                      <ArrowSmDownIcon v-if="sortOrder === 'desc'" class="h-5 w-5 text-gray-600" />
                      <ArrowSmUpIcon v-else class="h-5 w-5 text-gray-600" />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="atrs.length" class="bg-white">
                <tr v-for="(atr, index) in paginatedResults" :key="atr.docId" :class="index % 2 === 0 ? undefined : 'bg-gray-50'">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-blue-600 hover:underline cursor-pointer" @click="navigateToDocument(atr.docId, atr.requestedBy)">
                    {{ atr.shortId }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 sm:pl-6 text-sm text-gray-500">
                    <span v-if="atr.offersArray && atr.offersArray.length">
                      <span
                        v-for="offer in atr.offersArray"
                        :key="offer.offerId"
                        class="offer text-blue-600 hover:underline"
                      >
                        <router-link :to="`/selection-complete/${atr.docId}/${atr.requestedBy}`">{{ offer.candidate }}</router-link>
                      </span>
                      ({{ `${atr.offersArray.length - getCancelledOffers(index).length || 0}/${atr.maxOffers}` }})
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 sm:pl-6 text-sm text-gray-500">
                    {{ atr.taleo_vacancy_id ? atr.taleo_vacancy_id : '-' }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 sm:pl-6 text-sm text-gray-500">
                    {{ atr.modifiedDate ? formatDate(atr.modifiedDate, 'YYYY-MM-DD') : '-' }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 sm:pl-6 text-sm text-gray-500">
                    {{ atr.jobTitle ? atr.jobTitle  : '-' }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 sm:pl-6 text-sm text-gray-500">
                    {{ atr.hiringManager ? atr.hiringManager  : '-' }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 sm:pl-6 text-sm text-gray-500">
                    {{ atr.firstLineApproval ? atr.firstLineApproval : '-' }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 sm:pl-6 text-sm text-gray-500">
                    {{ atr.secondLineApproverEmail ? atr.secondLineApproverEmail : '-' }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 sm:pl-6 text-sm text-gray-500">
                    {{ atr.recruitment_owner_email ? atr.recruitment_owner_email : '-'}}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 sm:pl-6 text-sm text-gray-500">
                    {{ atr.docType}}
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-if="loaded && !atrs.length" class="text-gray-400 text-center p-8"> No Requests to show... </p>
          </div>
        </div>
        <div class="flex justify-center md:justify-end mx-1 md:mx-8 my-4 px-4 md:px-8 py-4 shadow rounded ring-1 ring-black ring-opacity-5">
          <button :disabled="pageNumber === 0" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-green-700 bg-white hover:bg-gray-50 disabled:opacity-25 disabled:text-gray-600 disabled:cursor-not-allowed" @click="prevPage">Prev</button>
          <button :disabled="(pageNumber + 1 === totalPages)" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-green-700 bg-white hover:bg-gray-50 disabled:opacity-25 disabled:text-gray-600 disabled:cursor-not-allowed" @click="nextPage">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import httpClient from '@/httpClient';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, onMounted, watch, computed, inject } from 'vue';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/solid';

const store = useStore();
const router = useRouter();
const atrs = ref([]);
const loaded = ref(false);
const stage = ref('starter.notifyBudgetApprover');
const pageNumber = ref(0);
const currentSort = ref('Short ID')
const sortOrder = ref('desc');
const stats = ref([]);
const searchTerm = ref('');

const formatDate = inject('formatDate');

const headings = [
  'Short ID',
  'Offers',
  'Taleo Reference Number',
  'Modified Date',
  'Job Title',
  'Hiring Manager',
  'First Line Approver',
  'Second Line Approver',
  'Resourcing Owner',
  'Doc Type'
]
const routes = {
  STARTER: 'view-starter',
  LEAVER: 'view-leaver',
  AMEND: 'view-amend',
  OFFER: 'view-offer'
}

const stages = inject('stages');

const sortables = {
  'Short ID': 'shortId',
  'Taleo Reference Number': 'taleo_vacancy_id',
  'Modified Date': 'modifiedDate',
  'Job Title': 'jobTitle',
  'Hiring Manager': 'hiringManager',
  'First Line Approver': 'firstLineApproval',
  'Second Line Approver': 'secondLineApproverEmail',
  'Resourcing Owner': 'recruitment_owner_email'
}

const documentsSorted = computed(() => {
  const sorted = [...atrs.value].sort((a, b) => {
    let val1;
    let val2;
    if (currentSort.value === 'Modified Date') {
      val1 = a[sortables[currentSort.value]];
      val2 = b[sortables[currentSort.value]];
    } else {
      val1 = a[sortables[currentSort.value]] ? a[sortables[currentSort.value]].toLowerCase() : '-';
      val2 = b[sortables[currentSort.value]] ? b[sortables[currentSort.value]].toLowerCase() : '-';
    }
    if (sortOrder.value === 'asc') {
      if (val1 < val2) return -1;
      else if (val1 > val2) return 1;
      else return 0;
    } else {
      if (val1 < val2) return 1;
      else if (val1 > val2) return -1;
      else return 0;
    } 
  });
  return sorted;
});

const paginatedResults = computed(() => {
  const filteredBySearch = documentsSorted.value.filter((item) => {
    const keys = Object.keys(item);
    let found = false;
    keys.forEach((key) => {
      if (item[key] && item[key].toString().toLowerCase().includes(searchTerm.value.toLowerCase())) {
        found = true;
        return true;
      }
    })
    if (found) return item;
  });
  setTotalPages(Math.ceil(filteredBySearch.length / 25));
  if (filteredBySearch.length <= 25) return filteredBySearch;
  else {
    const pageStart = pageNumber.value * 25;
    const pageEnd = pageNumber.value * 25 + 25;
    return filteredBySearch.slice(pageStart, pageEnd);
  }
});

const setTotalPages = (val) => totalPages.value = val;

const totalPages = ref(0);

const stageDropdownItems = computed(() => stages.filter((stage) => ![
  'starter.complete',
  'offer.complete',
  'leaver.complete',
  'amend.complete'
].includes(stage.stageName)))

const getCancelledOffers = (index) => atrs.value[index]?.offersArray.filter((offer) => offer.active === 'CANCELLED')


const profile = computed(() => store.getters.getProfile);

const findCount = (stage) => {
  const stat = stats.value?.find((item) => item.stage === stage);
  return stat?.count ?? 0
}

const totalCount = computed(() => stats.value.reduce((a, b) => a + (b.count || 0), 0))

const handleHeadingClick = (headingName) => {
  if (headingName === currentSort.value) {
    sortOrder.value === 'desc' ? sortOrder.value = 'asc' : sortOrder.value = 'desc';
  } else {
    if (sortables[headingName]) {
      currentSort.value = headingName;
      sortOrder.value = 'desc';
    }
  }
}

const navigateToDocument = async (id, docOwner) => {
  // find document
  const document = atrs.value.find((atr) => atr.docId === id);
  if (document && document.currentStageName === 'starter.notifyRecruitment') {
    router.push({ path: `/recruitment-team/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'starter.notifyRecruitmentUpdated') {
    router.push({ path: `/recruitment-update/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'starter.notifyRoleAdvertised') {
    router.push({ path: `/advert-raised/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'starter.notifyCVsCollected') {
    router.push({ path: `/ready-for-selection/${id}/${docOwner}` });
  } else if (document && (document.currentStageName === 'starter.notifyCandidateSelected' || document.currentStageName === 'starter.complete')) {
    router.push({ path: `/selection-complete/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyMakeOffer') {
    router.push({ path: `/offer-hr/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyPrepOffer') {
    router.push({ path: `/confirm/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyTermsHaveChanged') {
    router.push({ path: `/amend-terms/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyH2RAdmin') {
    router.push({ path: `/assign-admin-owner/${id}/${docOwner}` });
  } else if (document && document.currentStageName === 'offer.notifyOfferSent') {
    router.push({ path: `/sap-confirmation/${id}/${docOwner}` });
  } else {
    router.push({ path: `/${routes[document.docType]}/${id}/${docOwner}` });
  }
}

const fetchAtrs = async () => {
  if (stage.value === 'all') {
    fetchAllAtrs();
  } else {
    const response = await httpClient.post('/bff/resourcing-by-stage', {
      requestedBy: profile.value.email,
      stage: stage.value
    })
    atrs.value = response.data.body.Items;
  }
};

const fetchAllAtrs = async () => {
  atrs.value = [];
  for (const docType of ["STARTER",  "LEAVER", "AMEND", "OFFER"]) {
    const response = await httpClient.post('/bff/resourcing-all', {
      requestedBy: profile.value.email,
      docType
    })
    atrs.value = [...atrs.value, ...response.data.body.Items];
  }
};

const getAllStats = async () => {
  const response = await httpClient.post('/bff/all-stats', {
    requestedBy: profile.value.email
  });
  stats.value = response.data.body.Items
}

const nextPage = () => {
  if (pageNumber.value + 1 !== totalPages.value) pageNumber.value++;
};

const prevPage = () => {
  if (pageNumber.value !== 0) pageNumber.value--;
}

onMounted(async() => {
  store.dispatch('setIsLoading', true);
  if (profile.value.RESOURCING) {
    stage.value = 'starter.notifyRecruitment'
  }
  await fetchAtrs();
  await getAllStats();
  store.dispatch('setIsLoading', false);
  loaded.value = true;
});

watch(stage, async () => {
  store.dispatch('setIsLoading', true);
  pageNumber.value = 0;
  await fetchAtrs()
  store.dispatch('setIsLoading', false);
});
</script>
<style scoped>
.offer::after {
  content: ', ';
  word-wrap: break-all;
}
.offer:last-child::after {
  content: none;
}
</style>