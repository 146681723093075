<template>
  <form>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Annual Leave
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->

        <fieldset>
          <legend class="text-base font-medium text-gray-900">
            What is the employees current annual leave situation? *
          </legend>
          <span v-if="v$.formData.leave_situation.$error" class="text-red-500">
            * {{ v$.formData.leave_situation.$errors[0].$message }}
          </span>
          <div class="mt-4 space-y-4">
            <div class="flex items-start">
              <div class="h-5 flex items-center">
                <input
                  v-model="formData.leave_situation"
                  value="The employee has unused annual leave which needs to be paid (Credit)"
                  id="radio-button-leave_situation-credit"
                  name="leave_situation"
                  type="radio"
                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                  :class="{
                    'bg-gray-200 border-none cursor-not-allowed': disableForm,
                  }"
                  :disabled="disableForm"
                  @blur="v$.formData.leave_situation.$touch()"
                />
              </div>
              <div class="ml-3 text-sm">
                <label for="leave_situation" class="font-medium text-gray-700"
                  >The employee has unused annual leave which needs to be paid
                  (Credit)</label
                >
              </div>
            </div>
            <div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    v-model="formData.leave_situation"
                    value="The employee has used more than their annual leave entitlement, their pay will need to be deducted (Debit)"
                    id="radio-button-leave_situation-debit"
                    name="leave_situation"
                    type="radio"
                    class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                    :class="{
                      'bg-gray-200 border-none cursor-not-allowed': disableForm,
                    }"
                    :disabled="disableForm"
                    @blur="v$.formData.leave_situation.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="leave_situation" class="font-medium text-gray-700"
                    >The employee has used more than their annual leave
                    entitlement, their pay will need to be deducted
                    (Debit)</label
                  >
                </div>
              </div>
            </div>
            <div>
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    v-model="formData.leave_situation"
                    value="The employees annual leave is balanced"
                    id="radio-button-leave_situation-balanced"
                    name="leave_situation"
                    type="radio"
                    class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                    :class="{
                      'bg-gray-200 border-none cursor-not-allowed': disableForm,
                    }"
                    :disabled="disableForm"
                    @blur="v$.formData.leave_situation.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="leave_situation" class="font-medium text-gray-700"
                    >The employees annual leave is balanced</label
                  >
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <p class="text-sm text-red-700">
          If you have any queries in relation to annual leave please contact
          your local HR manager.
        </p>

        <div
          v-if="
            formData.leave_situation !==
            'The employees annual leave is balanced'
          "
        >
          <fieldset>
            <legend class="text-base font-medium text-gray-900">
              Is this in days or hours? *
            </legend>
            <span v-if="v$.formData.days_hours.$error" class="text-red-500">
              * {{ v$.formData.days_hours.$errors[0].$message }}
            </span>
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input
                    v-model="formData.days_hours"
                    value="Days"
                    id="radio-button-days"
                    name="days_hours"
                    type="radio"
                    class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                    :class="{
                      'bg-gray-200 border-none cursor-not-allowed': disableForm,
                    }"
                    :disabled="disableForm"
                    @blur="v$.formData.days_hours.$touch()"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="days_hours" class="font-medium text-gray-700"
                    >Days</label
                  >
                </div>
              </div>
              <div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      v-model="formData.days_hours"
                      value="Hours"
                      id="radio-button-hours"
                      name="days_hours"
                      type="radio"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          disableForm,
                      }"
                      :disabled="disableForm"
                      @blur="v$.formData.days_hours.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="days_hours" class="font-medium text-gray-700"
                      >Hours</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="col-span-6 mt-4">
            <label for="be_paid" class="block text-sm font-medium text-gray-700"
              >How much annual leave should be paid/deducted?</label
            >
            <input
              v-model="formData.be_paid"
              :disabled="disableForm"
              type="number"
              min="0"
              name="be_paid"
              id="be_paid-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
              :class="{
                'error-border': v$.formData.be_paid.$error,
                'border-red-500': v$.formData.be_paid.$error,
              }"
              @blur="v$.formData.be_paid.$touch()"
            />
            <span v-if="v$.formData.be_paid.$error" class="text-red-500">
              * {{ v$.formData.be_paid.$errors[0].$message }}
            </span>
          </div>

          <p class="text-sm text-red-700">
            The amount entered must be rounded up to the nearest 0.5 days, for
            example 7.23 should show as 7.5 and 7.66 to 8.0
          </p>
          <p class="text-sm text-red-700">
            Please note payment in lieu of holidays is subject to tax and
            national insurance.
          </p>
        </div>

        <div class="col-span-6">
          <label for="comments" class="block text-sm font-medium text-gray-700"
            >Comments on annual leave:</label
          >
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="comments"
              name="leave_comments"
              v-model="formData.leave_comments"
              rows="3"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
              :class="{
                'bg-gray-200 border-none cursor-not-allowed': disableForm,
              }"
              :disabled="disableForm"
            />
          </div>
        </div>

        <p class="text-sm text-red-700">
          Please note any comments related to the employees annual leave which
          you would like to share with the SSC Leavers Team.
        </p>
        <!-- End Form -->
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!disableForm">
        <button
          :disabled="v$.formData.$invalid"
          type="submit"
          class="bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400"
          @click.prevent="handleSave"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
export default {
  props: ["disableForm"],
  data() {
    return {
      v$: useVuelidate(),
    };
  },
  methods: {
    handleSave() {
      this.$emit("submit-form", { name: "formEight", formData: this.formData });
    },
  },
  computed: {
    formData() {
      return this.$store.getters.getLeaverFormData("formEight");
    },
  },
  watch: {
    "formData.leave_situation"(newVal) {
      if (newVal === "The employees annual leave is balanced") {
        this.formData.be_paid = undefined;
        this.formData.days_hours = undefined;
      }
    },
  },
  validations() {
    return {
      formData: {
        leave_situation: { required },
        be_paid: {
          requiredIf: helpers.withMessage(
            "Required if leave situation is not balanced",
            requiredIf(
              this.formData.leave_situation !==
                "The employees annual leave is balanced"
            )
          ),
        },
        days_hours: {
          requiredIf: helpers.withMessage(
            "Required if leave situation is not balanced",
            requiredIf(
              this.formData.leave_situation !==
                "The employees annual leave is balanced"
            )
          ),
        },
      },
    };
  },
};
</script>

<style scoped>
.error-border {
  border: 2px solid red;
}
</style>
