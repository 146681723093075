<template>
  <form class="space-y-8 divide-y divide-gray-200">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div class="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div>
          <h3 class="text-lg font-medium leading-6 text-gray-900">User Details</h3>
        </div>
        <div class="space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">First name(s) *</label>
            <div class="mt-1 sm:col-span-2 sm:mt-0">
              <input v-model="formData.firstName" type="text" name="first-name" id="first-name" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" @blur="v$.formData.firstName.$touch()" />
              <div v-if="v$.formData.firstName.$error">
                <p class="text-red-600">* {{ v$.formData.firstName.$errors[0].$message }}</p>
              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Last name *</label>
            <div class="mt-1 sm:col-span-2 sm:mt-0">
              <input v-model="formData.surname" type="text" name="last-name" id="last-name" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" @blur="v$.formData.surname.$touch()" />
              <div v-if="v$.formData.surname.$error">
                <p class="text-red-600">* {{ v$.formData.surname.$errors[0].$message }}</p>
              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="userID" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">User ID *</label>
            <div class="mt-1 sm:col-span-2 sm:mt-0">
              <input v-model="formData.userID" id="email" name="userID" type="text" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" @blur="v$.formData.userID.$touch()" />
              <div v-if="v$.formData.userID.$error">
                <p class="text-red-600">* {{ v$.formData.userID.$errors[0].$message }}</p>
              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Email address *</label>
            <div class="mt-1 sm:col-span-2 sm:mt-0">
              <input v-model="formData.email" id="email" name="email" type="email" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" @blur="v$.formData.email.$touch()" />
              <div v-if="v$.formData.email.$error">
                <p class="text-red-600">* {{ v$.formData.email.$errors[0].$message }}</p>
              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Job Title *</label>
            <div class="mt-1 sm:col-span-2 sm:mt-0">
              <input v-model="formData.jobTitle" id="job-title" name="jobTitle" type="text" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" @blur="v$.formData.email.$touch()" />
              <div v-if="v$.formData.jobTitle.$error">
                <p class="text-red-600">* {{ v$.formData.jobTitle.$errors[0].$message }}</p>
              </div>
            </div>
          </div>

          <div role="group" aria-labelledby="label-email-prefs" class="sm:border-t sm:border-gray-200">
            <div class="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
              <div>
                <div class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email-prefs">Roles *</div>
              </div>
              <div class="sm:col-span-2">
                <div class="max-w-lg">
                  <div class="mt-4 space-y-4">
                    <CheckList
                      :options="roles"
                      :error="v$.formData.roles.$error ? v$.formData.roles.$errors[0].$message : undefined"
                      :modelValue="formData.roles"
                      @blur="v$.formData.roles.$touch()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div role="group" aria-labelledby="label-email-prefs" class="sm:border-t sm:border-gray-200">
            <div class="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
              <div>
                <div class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email-prefs">Email Preferences *</div>
              </div>
              <div class="sm:col-span-2">
                <div class="max-w-lg">
                  <div class="mt-4 space-y-4">
                    <div v-if="v$.formData.emailPrefs.$error">
                      <p class="text-red-600">* {{ v$.formData.emailPrefs.$errors[0].$message }}</p>
                    </div>
                    <div class="flex items-center">
                      <input v-model="formData.emailPrefs" value="NO-EMAILS" id="pref-no-emails" name="emailPrefs" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" @blur="v$.formData.emailPrefs.$touch()" />
                      <label for="pref-no-emails" class="ml-3 block text-sm font-medium text-gray-700">NO EMAILS</label>
                    </div>
                    <div class="flex items-center">
                      <input v-model="formData.emailPrefs" value="INSTANT" id="pref-instant" name="emailPrefs" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" @blur="v$.formData.emailPrefs.$touch()" />
                      <label for="pref-instant" class="ml-3 block text-sm font-medium text-gray-700">INSTANT</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="userExists" class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="archived" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Archive user</label>
            <div class="mt-1 sm:col-span-2 sm:mt-0">
              <input 
                v-model="formData.archived"
                type="checkbox" 
                name="archived" 
                id="archived" 
                class="
                  focus:ring-indigo-500
                  h-4
                  w-4
                  text-indigo-600
                  border-gray-300
                  rounded
                  disabled:bg-gray-200
                  disabled:border-none
                  disabled:cursor-not-allowed" 
              />
            </div>
          </div>

          <div v-if="userExists" class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="disableCreateStarter" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Disable create starter</label>
            <div class="mt-1 sm:col-span-2 sm:mt-0">
              <input 
                v-model="formData.disableCreateStarter"
                type="checkbox" 
                name="disableCreateStarter" 
                id="disableCreateStarter" 
                class="
                  focus:ring-indigo-500
                  h-4
                  w-4
                  text-indigo-600
                  border-gray-300
                  rounded
                  disabled:bg-gray-200
                  disabled:border-none
                  disabled:cursor-not-allowed" 
              />
            </div>
          </div>

        </div>
      </div>
    </div>
    
    <div class="pt-5">
      <div class="flex justify-end">
        <div class="flex">
          <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 hover:underline" @click="$emit('cancel')">Cancel</button>
          <button :disabled="v$.$invalid" type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400" @click.prevent="handleCreateUser">Save</button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import httpClient from '@/httpClient';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import CheckList from '@/components/CheckList.vue';

export default {
  components: { CheckList },
  props: ['existingUser'],
  data() {
    return {
      v$: useVuelidate(),
      formData: {
        firstName: '',
        surname: '',
        userID: '',
        email: '',
        jobTitle: '',
        roles: [],
        emailPrefs: '',
        archived: false,
        disableCreateStarter: false
      },
      roles: ['ADMIN', 'H2R', 'HM', 'HROPS', 'RESOURCING'],
      userExists: this.existingUser,
    };
  },
  methods: {
    async handleCreateUser() {
      this.$store.dispatch('setIsLoading', true);
      try {
        const response = await httpClient.post('/users/add-user', this.formData);
        if (response.data.statusCode === 500) throw new Error();
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: 'Success!',
          message: 'Successfully created new user.'
        });
        this.$emit('cancel');
      } catch(err) {
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: 'Error!',
          message: 'Could not create new user at this time.'
        });
      }
      this.$store.dispatch('setIsLoading', false);
    }
  },
  created() {
    if (this.existingUser) {
      this.formData = this.existingUser;
    }
  },
  validations() {
    return {
      formData: {
        firstName: { required },
        surname: { required },
        userID: { required },
        email: { required, email },
        jobTitle: { required },
        roles: { required },
        emailPrefs: { required }
      }
    };
  },
}
</script>