<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Advertising Requirements
          </h3>
        </div>
        <!-- end title header -->

        <!-- Form -->
        <!-- Start advertise role? -->
        <div>
          <div>
            <p>Do you want the recruitment team to advertise the role? *</p>
          </div>
          <div class="mt-4 space-y-4">
            <div class="flex items-start">
              <div class="h-5 flex items-center">
                <input
                  value="Yes"
                  id="radio-button-recruitment-team-yes"
                  name="recruitmentTeam"
                  type="radio"
                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                  :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }"
                  :disabled="showApprovalSteps"
                  :checked="formData.recruitmentTeam === 'Yes'"
                  @change="handleRadioChange"
                  @blur="v$.formData.recruitmentTeam.$touch()"
                />
              </div>
              <div class="ml-3 text-sm">
                <label
                  for="recruitmentTeam"
                  class="font-medium text-gray-700 cursor-pointer"
                  >Yes</label
                >
              </div>
            </div>
            <div class="flex items-start">
              <div class="h-5 flex items-center">
                <input
                  value="No"
                  id="radio-button-recruitment-team-no"
                  name="recruitmentTeam"
                  type="radio"
                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                  :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                  }"
                  :disabled="showApprovalSteps"
                  :checked="formData.recruitmentTeam === 'No'"
                  @change="handleRadioChange"
                  @blur="v$.formData.recruitmentTeam.$touch()"
                />
              </div>
              <div class="ml-3 text-sm">
                <label
                  for="recruitmentTeam"
                  class="font-medium text-gray-700 cursor-pointer"
                  >No</label
                >
              </div>
            </div>
            <span
              v-if="v$.formData.recruitmentTeam.$errors.length"
              class="text-red-500"
            >
              * {{ v$.formData.recruitmentTeam.$errors[0].$message }}
            </span>
            <p class="text-sm text-gray-500">
              * If you would like to review the full recruitment policy please
              click here
            </p>
            <p class="text-sm text-red-500">
              * All vacancies should be advertised in line with European Social
              Agreement and approvers are instructed to challenge this decision.
            </p>
          </div>
          <!-- End advertise role? -->
          <!-- Checkbox on no selection-->
          <div
            class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5"
            v-show="formData.recruitmentTeam === 'No'"
          >
            <fieldset>
              <div>
                <p>Why have you chosen not to advertise? *</p>
              </div>
              <span
                v-if="v$.formData.chosen_not.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.chosen_not.$errors[0].$message }}
              </span>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-already-recruited"
                      name="chosen_not"
                      type="radio"
                      value="already_recruited"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :checked="formData.chosen_not === 'already_recruited'"
                      :disabled="showApprovalSteps"
                      @change="handleRadioChange"
                      @blur="v$.formData.chosen_not.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="chosen_not"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Already Recruited</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-internal-promotion"
                      name="chosen_not"
                      type="radio"
                      value="internal_promotion"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :disabled="showApprovalSteps"
                      :checked="formData.chosen_not === 'internal_promotion'"
                      @change="handleRadioChange"
                      @blur="v$.formData.chosen_not.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="internal_promotion"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Internal Promotion</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      id="radio-button-other"
                      name="chosen_not"
                      type="radio"
                      value="other"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :disabled="showApprovalSteps"
                      :checked="formData.chosen_not === 'other'"
                      @change="handleRadioChange"
                      @blur="v$.formData.chosen_not.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="chosen_not"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Other</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <!-- End Checkbox on no-->
          <div v-if="formData.recruitmentTeam === 'Yes'">
            <!-- Start advertise salary? -->
            <div
              class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <div class="col-span-6">
                <label
                  for="advertise"
                  class="block text-sm font-medium text-gray-70"
                  >Which wording would you like to use to advertise the salary?
                  *</label
                >
                <span
                  v-if="v$.formData.advertise.$errors.length"
                  class="text-red-500"
                >
                  * {{ v$.formData.advertise.$errors[0].$message }}
                </span>
                <select
                  id="adrequirements"
                  name="advertise"
                  autocomplete="advertise"
                  :value="formData.advertise"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  :class="{
                    'bg-gray-200 border-none cursor-not-allowed':
                      showApprovalSteps,
                    'error-border': v$.formData.advertise.$error,
                    'border-red-500': v$.formData.advertise.$error,
                  }"
                  :disabled="showApprovalSteps"
                  @change="handleInputChange"
                  @blur="v$.formData.advertise.$touch()"
                >
                  <option value="" selected disabled>Please select</option>
                  <option value="Don't Advertise Salary or Band">
                    Don't Advertise Salary or Band
                  </option>
                  <option
                    value="On offer is a salary of XXXX, dependent upon skills and experience"
                  >
                    On offer is a salary of XXXX, dependent upon skills and
                    experience
                  </option>
                  <option
                    value="On offer is a salary banding of XXXX-XXXX, dependent upon skills and experience"
                  >
                    On offer is a salary banding of XXXX-XXXX, dependent upon
                    skills and experience
                  </option>
                  <option value="On offer is a salary of XXXX per hour">
                    On offer is a salary of XXXX per hour
                  </option>
                  <option value="On offer is a salary of XXXX per annum">
                    On offer is a salary of XXXX per annum
                  </option>
                  <option value="Use my own specific wording (please specify)">
                    Use my own specific wording (please specify)
                  </option>
                </select>
              </div>
            </div>
            <!-- End advertise salary? -->
            <!-- Start Advertise - other -->
            <div
              v-if="
                formData.advertise ===
                'Use my own specific wording (please specify)'
              "
              class="mt-2"
            >
              <label for="advertiseOther"
                >Please specify the wording you would like to use *</label
              >
              <input
                type="text"
                name="advertiseOther"
                :value="formData.advertiseOther"
                class="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                :class="{
                  'bg-gray-200 border-none cursor-not-allowed':
                    showApprovalSteps,
                  'error-border': v$.formData.advertiseOther.$error,
                  'border-red-500': v$.formData.advertiseOther.$error,
                }"
                :disabled="showApprovalSteps"
                @input="handleInputChange"
                @blur="v$.formData.advertiseOther.$touch()"
              />
              <span
                v-if="v$.formData.advertiseOther.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.advertiseOther.$errors[0].$message }}
              </span>
            </div>
            <!-- Start Advertise - other -->
            <!-- Start Advertisement channels -->
            <div
              class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <div>
                <p>
                  What channels would you like to use to advertise the role? *
                </p>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="channels-internal"
                      id="checkbox-button-channels-internal"
                      name="channels"
                      type="checkbox"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.channels.includes('channels-internal')"
                      @change="handleCheckboxChange"
                      @blur="v$.formData.channels.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="channels"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Equans Jobs Internal (*mandatory for all advertised roles
                      as per European Social Agreement) *FREE</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="channels-external"
                      id="checkbox-button-channels-external"
                      name="channels"
                      type="checkbox"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.channels.includes('channels-external')"
                      @change="handleCheckboxChange"
                      @blur="v$.formData.channels.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="channels"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Equans Jobs External *FREE</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="channels-agency"
                      id="checkbox-button-channels-agency"
                      name="channels"
                      type="checkbox"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.channels.includes('channels-agency')"
                      @change="handleCheckboxChange"
                      @blur="v$.formData.channels.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="channels"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Approved External Agency *COSTS WILL BE INCURRED</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="channels-indeed"
                      id="checkbox-button-channels-indeed"
                      name="channels"
                      type="checkbox"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.channels.includes('channels-indeed')"
                      @change="handleCheckboxChange"
                      @blur="v$.formData.channels.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="channels"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Indeed and Universal Jobs *FREE</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="channels-linkedin"
                      id="checkbox-button-channels-linkedin"
                      name="channels"
                      type="checkbox"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.channels.includes('channels-linkedin')"
                      @change="handleCheckboxChange"
                      @blur="v$.formData.channels.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="channels"
                      class="font-medium text-gray-700 cursor-pointer"
                      >LinkedIn *COSTS WILL BE INCURRED</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="channels-website"
                      id="checkbox-button-channels-website"
                      name="channels"
                      type="checkbox"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.channels.includes('channels-website')"
                      @change="handleCheckboxChange"
                      @blur="v$.formData.channels.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="channels"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Other Websites *COSTS WILL BE INCURRED</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="channels-other"
                      id="checkbox-button-channels-other"
                      name="channels"
                      type="checkbox"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.channels.includes('channels-other')"
                      @change="handleCheckboxChange"
                      @blur="v$.formData.channels.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="channels"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Other (please specify)</label
                    >
                  </div>
                </div>
                <span
                  v-if="v$.formData.channels.$errors.length"
                  class="text-red-500"
                >
                  {{ v$.formData.channels.$errors[0].$message }}
                </span>
                <div v-if="formData.channels.includes('channels-other')">
                  <span
                    v-if="v$.formData.channelsOther.$errors.length"
                    class="text-red-500"
                  >
                    * {{ v$.formData.channelsOther.$errors[0].$message }}
                  </span>
                  <input
                    type="text"
                    name="channelsOther"
                    :value="formData.channelsOther"
                    class="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    :class="{
                      'bg-gray-200 border-none cursor-not-allowed':
                        showApprovalSteps,
                    }"
                    :disabled="showApprovalSteps"
                    @input="handleInputChange"
                    @blur="v$.formData.channelsOther.$touch()"
                  />
                </div>
              </div>
            </div>
            <!-- End Advertisement channels -->
            <!-- Start shortlist applications? -->
            <div
              class="grid grid-cols-3 gap-6 mt-4 sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <div>
                <p>
                  Do you want the recruitment team to shortlist the
                  applications? *
                </p>
              </div>
              <div class="mt-4 space-y-4">
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="Yes"
                      id="radio-button-recruitment-shortlist-yes"
                      name="recruitmentShortlist"
                      type="radio"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.recruitmentShortlist === 'Yes'"
                      @change="handleRadioChange"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="recruitmentShortlist"
                      class="font-medium text-gray-700 cursor-pointer"
                      >Yes</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="No"
                      id="radio-button-recruitment-shortlist-no"
                      name="recruitmentShortlist"
                      type="radio"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
                      :class="{
                        'bg-gray-200 border-none cursor-not-allowed':
                          showApprovalSteps,
                      }"
                      :disabled="showApprovalSteps"
                      :checked="formData.recruitmentShortlist === 'No'"
                      @change="handleRadioChange"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label
                      for="recruitment-shortlist-no"
                      class="font-medium text-gray-700 cursor-pointer"
                      >No</label
                    >
                  </div>
                </div>
                <span
                  v-if="v$.formData.recruitmentShortlist.$errors.length"
                  class="text-red-500"
                >
                  * {{ v$.formData.recruitmentShortlist.$errors[0].$message }}
                </span>
              </div>
            </div>
            <!-- End shortlist applications? -->
          </div>
        </div>
        <!-- End Form -->
      </div>
    </div>
    <!-- Save button -->
    <div
      v-if="!showApprovalSteps"
      class="px-4 py-3 bg-gray-50 text-right sm:px-6"
    >
      <button
        class="mr-2 bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        @click.prevent="handleResetForm"
      >
        Reset Form
      </button>
      <button
        :disabled="v$.$invalid"
        type="submit"
        class="bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400"
        @click="handleSave"
      >
        Next
      </button>
    </div>
    <!-- End Save button -->
  </form>
</template>

<script>
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";

export default {
  name: "formSeven",
  props: ["formData", "showApprovalSteps"],
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData,
    };
  },
  validations() {
    return {
      formData: {
        recruitmentTeam: {
          required,
        },
        advertiseOther: {
          requiredIf: helpers.withMessage(
            "Please specify your own wording for the salary advertisement",
            requiredIf(
              this.formData.advertise ===
                "Use my own specific wording (please specify)"
            )
          ),
        },
        advertise: {
          requiredIf: helpers.withMessage(
            "* Value is Required",
            requiredIf(this.formData.recruitmentTeam === "Yes")
          ),
        },
        channels: {
          requiredIf: helpers.withMessage(
            "* Value is Required",
            requiredIf(this.formData.recruitmentTeam === "Yes")
          ),
        },
        channelsOther: {
          requiredIf: helpers.withMessage(
            "Please specify other advertisement channel...",
            requiredIf(
              this.formData.channels &&
                this.formData.channels.includes("channels-other")
            )
          ),
        },
        recruitmentShortlist: {
          requiredIf: helpers.withMessage(
            "* Value is Required",
            requiredIf(this.formData.recruitmentTeam === "Yes")
          ),
        },
        chosen_not: {
          requiredIf: helpers.withMessage(
            "* Value is required",
            requiredIf(this.formData.recruitmentTeam === "No")
          ),
        },
      },
    };
  },
  methods: {
    handleSave(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submit-form", {
          name: "formSeven",
          formData: this.formData,
        });
      } else {
        this.store.dispatch("setMessage", {
          type: "error",
          title: "ERROR!",
          message: "Please fill out all the required fields",
        });
      }
    },
    handleInputChange(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit("update:formData", {
        formName: "formSeven",
        formData: this.model,
      });
    },
    handleCheckboxChange(event) {
      const { name, value, checked } = event.target;
      if (checked) {
        this.model[name].push(value);
      } else {
        const index = this.model[name].indexOf(value);
        this.model[name].splice(index, 1);
      }
      this.$emit("update:formData", {
        formName: "formSeven",
        formData: this.model,
      });
    },
    handleRadioChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      this.model[name] = value;
      this.$emit("update:formData", {
        formName: "formSeven",
        formData: this.model,
      });
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit("update:reset", "formSeven");
    },
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    },
    "model.recruitmentTeam"() {
      this.model.channelsOther = "";
      this.model.chosen_not = "";
      this.model.advertise = "";
      this.model.recruitmentShortlist = "";
      this.model.channels = [];
    },
  },
};
</script>

<style scoped>
.error-border {
  border: 2px solid red;
}
</style>
