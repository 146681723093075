<template>
  <div>
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <p class="mt-2 text-sm text-gray-700">A list of all the users in your account including their name, title, email
          and role.</p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button type="button"
          class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          @click="createNewUser">Add user</button>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="flex flex-col lg:flex-row w-full my-4">
        <div class="basis-1/5">
          <label for="search-by">Search by:</label>
          <select v-model="searchBy"
            class="mb-2 grow block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            name="search-by" id="search-by">
            <option value="id">User Id</option>
            <option value="email">Email</option>
          </select>
          <label for="include-archived" class="text-sm text-gray-700">Include archived users:</label>
          <input v-model="archived" type="checkbox" name="include-archived" id="include-archived" class="
                focus:ring-indigo-500
                h-3
                w-3
                ml-2
                text-indigo-600
                border-gray-300
                rounded
                disabled:bg-gray-200
                disabled:border-none
                disabled:cursor-not-allowed
              ">
        </div>
        <div class="basis-4/5 lg:ml-3 mt-4 lg:mt-0">
          <label for="search-user">Search:</label>
          <div class="flex flex-col sm:flex-row">
            <input v-model="searchTerm"
              class="basis-4/5 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              type="text" name="search-user">
            <button
              class="basis-1/5 sm:ml-2 mt-2 sm:mt-0 w-full inline-flex justify-center rounded-md border border-transparent bg-green-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400"
              @click.prevent="searchUsers">Search</button>
          </div>
        </div>
      </div>
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle px-4 md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">User ID
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Name</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role(s)</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-if="!people.length">
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center text-lg" colspan="4"
                    v-if="!searched">Please use the search bar to search for user(s)</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center text-lg" colspan="4" v-else>No
                    users found...</td>
                </tr>
                <tr v-for="person in people" :key="person.email">
                  <template v-if="archived || !person.archived">
                    <td
                      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-blue-700 sm:pl-6 hover:underline cursor-pointer"
                      @click.prevent="handleUserClick(person)">{{ person.userID }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.Title }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ person.email }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span v-if="person.ADMIN">ADMIN </span>
                      <span v-if="person.H2R">H2R </span>
                      <span v-if="person.HM">HM </span>
                      <span v-if="person.HROPS">HROPS </span>
                      <span v-if="person.RESOURCING">RESOURCING </span>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <ModalWindow v-if="showModal" size="5xl" @close="toggleModal">
      <template v-slot:icon>
        <UserCircleIcon class="h-12 w-12 text-green-600" aria-hidden="true" />
      </template>
      <template v-slot:title>{{ selectedUser ? 'Edit User' : 'Add User' }}</template>
      <template v-slot:subtitle>Please fill out the details of the user</template>
      <template v-slot:main>
        <UserForm :existingUser="selectedUser" @cancel="toggleModal" />
      </template>
    </ModalWindow>
  </div>
</template>

<script setup>
import httpClient from '@/httpClient';
import ModalWindow from '@/components/ModalWindow.vue';
import UserForm from '@/components/admin/admin-components/users/UserForm.vue';
import { UserCircleIcon } from '@heroicons/vue/solid';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const profile = computed(() => store.getters.getProfile);

const showModal = ref(false);
const searchBy = ref('id');
const searchTerm = ref('');
const people = ref([]);
const selectedUser = ref();
const searched = ref(false);
const archived = ref(false);

const toggleModal = () => showModal.value = !showModal.value;

const searchUsers = async () => {
  store.dispatch('setIsLoading', true);
  try {
    const payload = {
      userID: profile.value.userID,
      searchBy: searchBy.value,
      searchTerm: searchTerm.value,
    }
    const response = await httpClient.post('/users/list-users', payload);
    searched.value = true;
    people.value = response.data.body.Items;
  } catch (err) {
    store.dispatch('setNotificationAll', {
      visible: true,
      type: 'error',
      title: 'Error!',
      message: 'Could not get user(s) at this time.'
    })
  }
  store.dispatch('setIsLoading', false);
};

const handleUserClick = (user) => {
  selectedUser.value = {
    firstName: user.firstName || '',
    surname: user.surname || '',
    userID: user.userID,
    email: user.email,
    jobTitle: user.jobTitle || user.Title,
    roles: [],
    emailPrefs: user.emailPrefs,
    archived: user.archived || false,
    disableCreateStarter: user.disableCreateStarter || false
  };
  ['ADMIN', 'H2R', 'HM', 'HROPS', 'RESOURCING'].forEach((key) => {
    if (user[key]) {
      selectedUser.value.roles.push(key);
    }
  });
  toggleModal();
};

const createNewUser = () => {
  selectedUser.value = undefined;
  toggleModal();
}
</script>