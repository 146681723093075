<template>
  <div>
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Employee Details
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->

        <!-- IT Account -->
        <div
          class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
        >
          <fieldset>
            <legend class="text-base font-medium text-gray-900">
              Does the employee have an I.T. account? *
            </legend>
            <span v-if="v$.formData.itAccount.$error" class="text-red-500">
              * {{ v$.formData.itAccount.$errors[0].$message }}
            </span>
            <div class="mt-4 space-y-4">
              <div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="Yes"
                      id="itAccount-yes"
                      name="itAccount"
                      type="radio"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer disabled:bg-gray-200 disabled:cursor-not-allowed"
                      :checked="formData.itAccount === 'Yes'"
                      :disabled="disableForm"
                      @change="handleRadioChange"
                      @blur="v$.formData.itAccount.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="itAccount" class="font-medium text-gray-700"
                      >Yes</label
                    >
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="h-5 flex items-center">
                    <input
                      value="No"
                      id="itAccount-no"
                      name="itAccount"
                      type="radio"
                      class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer disabled:bg-gray-200 disabled:cursor-not-allowed"
                      :checked="formData.itAccount === 'No'"
                      :disabled="disableForm"
                      @change="handleRadioChange"
                      @blur="v$.formData.itAccount.$touch()"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="itAccount" class="font-medium text-gray-700"
                      >No</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <!-- IT Account end -->

        <!-- Employee name -->
        <div class="col-span-6">
          <label
            for="employeeName"
            class="block text-sm font-medium text-gray-700"
            >Employee's full name *</label
          >
          <input
            :value="formData.employeeName"
            :disabled="disableForm"
            type="text"
            name="employeeName"
            id="employee-name-input"
            autocomplete="name"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
            :class="{
              'error-border': v$.formData.employeeName.$error,
              'border-red-500': v$.formData.employeeName.$error,
            }"
            @input="handleInput"
            @blur="v$.formData.employeeName.$touch()"
          />
          <span v-if="v$.formData.employeeName.$error" class="text-red-500">
            * {{ v$.formData.employeeName.$errors[0].$message }}
          </span>
        </div>
        <!-- Employee name end -->

        <!-- Employee Number -->
        <div class="col-span-6">
          <label
            for="employeeNumber"
            class="block text-sm font-medium text-gray-700"
            >Employee number *</label
          >
          <input
            :value="formData.employeeNumber"
            :disabled="disableForm"
            type="text"
            name="employeeNumber"
            id="employee-number-input"
            autocomplete="name"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200"
            :class="{
              'error-border': v$.formData.employeeNumber.$error,
              'border-red-500': v$.formData.employeeNumber.$error,
            }"
            @input="handleInput"
            @blur="v$.formData.employeeNumber.$touch()"
          />
          <span v-if="v$.formData.employeeNumber.$error" class="text-red-500">
            * {{ v$.formData.employeeNumber.$errors[0].$message }}
          </span>
        </div>
        <!-- Employee Number end -->

        <!-- Division -->
        <div class="col-span-6">
          <label for="division" class="block text-sm font-medium text-gray-700"
            >Division *</label
          >
          <select
            id="division"
            name="division"
            autocomplete="division"
            :value="formData.division"
            :disabled="disableForm"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:bg-gray-200 disabled:cursor-not-allowed"
            :class="{
              'error-border': v$.formData.division.$error,
              'border-red-500': v$.formData.division.$error,
            }"
            @change="handleInput"
            @blur="v$.formData.division.$touch()"
          >
            <template v-if="disableForm">
              <option
                v-for="item in configItems.DIVISIONS"
                :key="item.ID"
                :value="item.NAME"
              >
                {{ item.NAME }}
              </option>
            </template>
            <template v-else>
              <option
                v-for="item in activeDivisions"
                :key="item.ID"
                :value="item.NAME"
              >
                {{ item.NAME }}
              </option>
            </template>
          </select>
          <span v-if="v$.formData.division.$error" class="text-red-500">
            * {{ v$.formData.division.$errors[0].$message }}
          </span>
        </div>
        <!-- Division end -->

        <!-- Company Code -->
        <div class="col-span-6">
          <label for="v" class="block text-sm font-medium text-gray-700"
            >Company Code *</label
          >
          <select
            id="company_code"
            name="company_code"
            autocomplete="company_code"
            :value="formData.company_code"
            :disabled="disableForm"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:bg-gray-200 disabled:cursor-not-allowed"
            :class="{
              'error-border': v$.formData.company_code.$error,
              'border-red-500': v$.formData.company_code.$error,
            }"
            @change="handleInput"
            @blur="v$.formData.company_code.$touch()"
          >
            <template v-if="disableForm">
              <option
                v-for="item in configItems?.ENTITIES"
                :key="item.entity"
                :value="item.entity"
              >
                {{ item.entity }}
              </option>
            </template>
            <template v-else>
              <option
                v-for="item in activeEntities"
                :key="item.entity"
                :value="item.entity"
              >
                {{ item.entity }}
              </option>
            </template>
          </select>
          <span v-if="v$.formData.company_code.$error" class="text-red-500">
            * {{ v$.formData.company_code.$errors[0].$message }}
          </span>
        </div>
        <!-- Company Code end -->

        <!-- Sector Code -->
        <div class="col-span-6">
          <label
            for="sector_code"
            class="block text-sm font-medium text-gray-700"
            >Sector *</label
          >
          <select
            id="sector_code"
            name="sector_code"
            autocomplete="sector_code"
            :value="formData.sector_code"
            :disabled="disableForm"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:bg-gray-200 disabled:cursor-not-allowed"
            :class="{
              'error-border': v$.formData.sector_code.$error,
              'border-red-500': v$.formData.sector_code.$error,
            }"
            @change="handleInput"
            @blur="v$.formData.sector_code.$touch()"
          >
            <template v-if="disableForm">
              <option
                v-for="item in currentDivision?.SECTORS"
                :key="item.ID"
                :value="item.NAME"
              >
                {{ item.NAME }}
              </option>
            </template>
            <template v-else>
              <option
                v-for="item in activeSectors"
                :key="item.ID"
                :value="item.NAME"
              >
                {{ item.NAME }}
              </option>
            </template>
          </select>
          <span v-if="v$.formData.sector_code.$error" class="text-red-500">
            * {{ v$.formData.sector_code.$errors[0].$message }}
          </span>
        </div>
        <!-- Sector end -->

        <!-- End Form -->
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!disableForm">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400"
          @click.prevent="handleSaveForm"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  props: ["formData", "configItems", "disableForm"],
  data() {
    return {
      model: this.formData,
      v$: useVuelidate(),
    };
  },
  methods: {
    handleInput(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit("update:formData", {
        formName: "formTwo",
        formData: this.model,
      });
    },
    handleRadioChange(event) {
      const { name, value } = event.target;
      this.model[name] = value;
      this.$emit("update:formData", {
        formName: "formTwo",
        formData: this.model,
      });
    },
    handleSaveForm() {
      this.$emit("submit-form", { name: "formTwo", formData: this.formData });
    },
  },
  computed: {
    activeDivisions() {
      return this.configItems?.DIVISIONS?.filter((div) => div.LKStatus) ?? [];
    },
    activeEntities() {
      return (
        this.configItems?.ENTITIES?.filter((entity) => entity.LKStatus) ?? []
      );
    },
    activeSectors() {
      return (
        this.currentDivision?.SECTORS?.filter((sector) => sector.LKStatus) ?? []
      );
    },
    currentDivision() {
      return (
        this.configItems?.DIVISIONS?.find(
          (div) => div.NAME === this.formData.division
        ) ?? []
      );
    },
  },
  validations() {
    return {
      formData: {
        itAccount: { required },
        employeeName: { required },
        employeeNumber: { required },
        division: { required },
        company_code: { required },
        sector_code: { required },
      },
    };
  },
};
</script>

<style scoped>
.error-border {
  border: 2px solid red;
}
</style>
