<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Start Details
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <div>
          <div class="col-span-6 mt-4">
            <label
              for="employeeNumber"
              class="block text-sm font-medium text-gray-700"
              >Line manager employee number: *</label
            >
            <input
              :value="formData?.employeeNumber"
              type="text"
              name="employeeNumber"
              id="employeeNumber-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              :class="{
                'bg-gray-200 border-none cursor-not-allowed': disableForm,
              }"
              :disabled="disableForm"
              @input="handleInput"
              @blur="v$.formData.employeeNumber.$touch()"
            />
            <span v-if="v$.formData.employeeNumber.$error" class="text-red-500">
              * {{ v$.formData.employeeNumber.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 mt-4">
            <label
              for="managerName"
              class="block text-sm font-medium text-gray-700"
              >Line manager full name: *</label
            >
            <input
              :value="formData?.managerName"
              type="text"
              name="managerName"
              id="managerName-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              :class="{
                'bg-gray-200 border-none cursor-not-allowed': disableForm,
              }"
              :disabled="disableForm"
              @input="handleInput"
              @blur="v$.formData.managerName.$touch()"
            />
            <span v-if="v$.formData.managerName.$error" class="text-red-500">
              * {{ v$.formData.managerName.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 mt-4">
            <label
              for="reportTo"
              class="block text-sm font-medium text-gray-700"
              >Who to report to on day 1: *</label
            >
            <input
              :value="formData?.reportTo"
              type="text"
              name="reportTo"
              id="reportTo-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              :class="{
                'bg-gray-200 border-none cursor-not-allowed': disableForm,
              }"
              :disabled="disableForm"
              @input="handleInput"
              @blur="v$.formData.reportTo.$touch()"
            />
            <span v-if="v$.formData.reportTo.$error" class="text-red-500">
              * {{ v$.formData.reportTo.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 mt-4">
            <label
              for="locationFirstLine"
              class="block text-sm font-medium text-gray-700"
              >Work location first line of address: *</label
            >
            <input
              :value="formData?.locationFirstLine"
              type="text"
              name="locationFirstLine"
              id="locationFirstLine-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              :class="{
                'bg-gray-200 border-none cursor-not-allowed': disableForm,
              }"
              :disabled="disableForm"
              @input="handleInput"
              @blur="v$.formData.locationFirstLine.$touch()"
            />
            <span
              v-if="v$.formData.locationFirstLine.$error"
              class="text-red-500"
            >
              * {{ v$.formData.locationFirstLine.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 mt-4">
            <label
              for="locationPostcode"
              class="block text-sm font-medium text-gray-700"
              >Work location postcode: *</label
            >
            <input
              :value="formData?.locationPostcode"
              type="text"
              name="locationPostcode"
              id="locationPostcode-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              :class="{
                'bg-gray-200 border-none cursor-not-allowed': disableForm,
              }"
              :disabled="disableForm"
              @input="handleInput"
              @blur="v$.formData.locationPostcode.$touch()"
            />
            <span
              v-if="v$.formData.locationPostcode.$error"
              class="text-red-500"
            >
              * {{ v$.formData.locationPostcode.$errors[0].$message }}
            </span>
          </div>

          <div class="col-span-6 mt-4">
            <label
              for="reasonableArea"
              class="block text-sm font-medium text-gray-700"
              >Reasonable area where the employee could work:</label
            >
            <textarea
              :value="formData?.reasonableArea"
              id="reasonableArea-input"
              name="reasonableArea"
              rows="3"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
              :class="{
                'bg-gray-200 border-none cursor-not-allowed': disableForm,
              }"
              :disabled="disableForm"
              @input="handleInput"
            />
          </div>

          <div class="col-span-6 mt-4">
            <label
              for="whereToReport"
              class="block text-sm font-medium text-gray-700"
              >Where to report on day 1, if different from usual work
              location:</label
            >
            <textarea
              :value="formData?.whereToReport"
              id="whereToReport-input"
              name="whereToReport"
              rows="3"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
              :class="{
                'bg-gray-200 border-none cursor-not-allowed': disableForm,
              }"
              :disabled="disableForm"
              @input="handleInput"
            />
          </div>

          <div class="col-span-6 mt-4">
            <label
              for="startDateTime"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >Start date and time: *</label
            >
            <DatePicker
              :value="formData?.startDateTime"
              timePicker
              @change="handleDateChange"
            />
            <span v-if="v$.formData.startDateTime.$error" class="text-red-500">
              * {{ v$.formData.startDateTime.$errors[0].$message }}
            </span>
          </div>

          <p class="text-sm text-red-700">
            * <span class="font-bold">Please Note:</span> If a start date has
            yet to be confirmed please enter 31/12/1999 00:00
          </p>

          <div
            class="col-span-6 mt-4"
            v-if="atr?.formThree?.employmentContract === 'Fixed Term'"
          >
            <label
              for="fixedTermEnd"
              class="block text-sm font-medium text-gray-700"
              >End date of fixed term contract: *</label
            >
            <input
              :value="formData?.fixedTermEnd"
              type="date"
              min="0"
              name="fixedTermEnd"
              id="fixedTermEnd-input"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              @input="handleInput"
              @blur="v$.formData.fixedTermEnd.$touch()"
            />
            <span v-if="v$.formData.fixedTermEnd.$error" class="text-red-500">
              * {{ v$.formData.fixedTermEnd.$errors[0].$message }}
            </span>
          </div>
        </div>

        <!-- End Form -->
      </div>
      <div
        v-if="
          editable ||
          (!disableForm &&
            document.currentStageName === 'offer.notifyPrepOffer')
        "
        class="px-4 py-3 bg-gray-50 text-right sm:px-6"
      >
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400"
          @click.prevent="submitForm"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import DatePicker from "@/components/DatePicker.vue";

export default {
  props: ["formData", "disableForm", "atr", "document", "editable"],
  emits: ["update:formData", "submit-form", "complete-offer"],
  data() {
    return {
      model: this.formData,
      v$: useVuelidate(),
    };
  },
  components: { DatePicker },
  methods: {
    handleInput(event) {
      const { name, value } = event.target;
      this.model[name] = value;

      this.$emit("update:formData", {
        formName: "startDetails",
        formData: this.model,
      });
    },
    handleDateChange(date) {
      this.model.startDateTime = date;

      this.$emit("update:formData", {
        formName: "startDetails",
        formData: this.model,
      });
    },
    submitForm() {
      this.$emit("submit-form", { name: "startDetails", formData: this.model });
    },
  },

  validations() {
    return {
      formData: {
        employeeNumber: { required },
        managerName: { required },
        reportTo: { required },
        locationFirstLine: { required },
        locationPostcode: { required },
        startDateTime: { required },
        fixedTermEnd: {
          requiredif: helpers.withMessage(
            "Value is required",
            requiredIf(this.atr?.formThree?.employmentContract === "Fixed Term")
          ),
        },
      },
    };
  },
};
</script>
