<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Next Steps
          </h3>
        </div>
        <!-- end title header -->
        
        <!-- First Line Approval -->
        <div>
        <div class="col-span-6">
          <p>Who will be providing first line approval for this ATR? *</p>
          <user-search 
            group="HM" 
            v-model:selectedEmail="model.firstLineApproval"
            :read-only="isFirstLineSelected"
            :errors="v$.formData.firstLineApproval.$errors"
            @updated-user="handleUser($event)" />
        </div>

          <!-- End First Line Approval -->

          <p class="mt-1 text-sm text-gray-500">
            *You can either type their name (surname then space then first name)
            e.g. Bloggs Joe and select the person icon or you can open the address
            book and search for their name (this is linked to the email
            directory).
          </p>
          
          <!-- Save for later button -->
          <div v-if="!showApprovalSteps" class="my-4">
            <button
              :disabled="v$.$invalid"
              type="submit"
              class="
                bg-green-500
                border border-transparent
                rounded-md
                shadow-sm
                py-2
                px-4
                inline-flex
                justify-center
                text-sm
                font-medium
                text-white
                hover:bg-green-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
                disabled:cursor-not-allowed
                disabled:bg-gray-200
                disabled:text-gray-400
              "
              @click="submitForm"
            >
              Save
            </button>
            <p class="my-4">
              * Once all mandatory fields (*) have been completed, you will be able to see a Submit button to allow you to
              submit the ATR to the next stage in the process. If you cannot see a Submit button, then you will need to
              review the form and complete any missing information.
            </p>
          </div>
          <div class="mt-8">
            <h3 class="text-lg my-2">Right To Work</h3>
            <p class="my-2 text-sm font-medium text-gray-700">New starters to the business:</p>
            <p class="my-2 text-sm font-medium text-gray-700">
              Recruitment is a legal process so all relevant documentation must be completed and correct.
              If not a contract cannot be issued and the new starter will not be paid.
            </p>
            <p class="my-2 text-sm font-medium text-gray-700">
              I confirm that the following attachments have been included:
            <ul class="list-disc list-inside marker:text-black">
              <li class="text-sm font-medium text-gray-700">Verified and signed copy of Right to Work in the UK or where
                applicable proof of an online check via the Home Office online service</li>
              <li class="text-sm font-medium text-gray-700">Proof of their identity (usually a passport) to support Home
                Office online check</li>
              <li class="text-sm font-medium text-gray-700">Candidate's CV</li>
              <li class="text-sm font-medium text-gray-700">Job Description</li>
            </ul>
            </p>
            <!-- TODO: Add policy link-->
            <p class="my-2 text-sm font-medium text-gray-700">Policy (LINK)</p>
            <p class="my-2 text-sm font-medium text-gray-700">You must have had sight of the original Right to Work
              document. You should take a copy and write on each copy: “I certify that this is a true
              copy of the original”, then sign, print your name, note your job title, and date the copy.
            </p>
            <p class="my-2 text-sm font-medium text-gray-700">
              For EEA Nationals you will need to check proof of their immigration status – either (i) proof of their EEA
              Settled status – this has to be done
              via the Home Office online service through the provision of a share code; or (ii) if they meet the new
              requirements of the immigration system.
            </p>
            <p class="my-2 text-sm font-medium text-gray-700">Please be sure to check the Right to Work Policy to ensure
              you have provided the correct documentation. The Offer will be rejected if the
              incorrect documentation is provided.
            </p>
            <p class="my-2 text-sm font-medium text-gray-700">Tips to avoid Right to Work rejections:</p>
            <p class="my-2 text-sm font-medium text-gray-700">Right to work evidence must be verified with the
              certification statement above, signature, printed name, and date.</p>
            <p class="my-2 text-sm font-medium text-gray-700">
              Where an employee has submitted evidence of their National Insurance Number and their Birth Certificate as
              their Right to Work evidence,
              they must submit their full birth certificate showing at least one parent’s details.
            </p>
            <p class="my-2 text-sm font-medium text-gray-700">You must also provide proof of the National Insurance Number
              or an official document showing the National Insurance Number</p>
            <p class="my-2 text-sm font-medium text-gray-700">Where an employee has provided a Biometric Card you should
              take copies of the front and back of the Biometric Card (Biometric Residence Permit).</p>
            <p class="my-2 text-sm font-medium text-gray-700">
              The names on all documentation must match, e.g. if passport or biometric card is in their maiden name, then
              the employee must submit their marriage
              certificate to show the name change.
            </p>
            <p class="my-2 text-sm font-medium text-gray-700">
              Evidence of an Home Office online check being completed (where applicable). Watch the Right to Work
              presentation here. Internal appointments:
              Right to work documentation will already be on file for the employee, therefore only the new job description
              is required. Once ready, please scan and
              attach the copies below.
            </p>
            <p class="my-2 text-sm font-medium text-gray-700">Internal appointments</p>
            <p class="my-2 text-sm font-medium text-gray-700">Right to work documentation will already be on file for the
              employee, therefore only the new job description is required.</p>
          </div>
          <!-- End Save for later button -->
        </div>
      </div>
    </div>
    <!-- Save button -->
    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
      <button
        v-if="!showApprovalSteps"
        :disabled="!atrReadyToSubmit"
        type="submit"
        class="
          bg-green-500
          border border-transparent
          rounded-md
          shadow-sm
          py-2
          px-4
          inline-flex
          justify-center
          text-sm
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          disabled:cursor-not-allowed
          disabled:bg-gray-200
          disabled:text-gray-400
        "
        @click="handleCompleteAtr"
      >
        Submit ATR
      </button>
    </div>
    <!-- End Save button -->
  </form>
</template>

<script>
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import UserSearch from '@/components/userDropdownSearch.vue'
export default {
  name: "FormTwelve",
  props: ['formData', 'atrReadyToSubmit', 'showApprovalSteps'],
  components: {
    UserSearch
  },
  data() {
    return {
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData,
    };
  },
  computed: {
    isFirstLineSelected(){
      return this.model?.firstLineApproval ? true : false;
    }
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submit-form", {
          name: "formTwelve",
          formData: this.formData,
        });
      } else {
        this.store.dispatch("setMessage", {
          type: "error",
          title: "ERROR!",
          message: "Please fill out all the required fields",
        });
      }
    },
    handleRequestMoreInfo(event) {
      event.preventDefault();
      this.store.dispatch('requestMoreInfo');
    },
    handleInput(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit('update:formData', { formName: 'formTwelve', formData: this.model });
    },
    handleUser() {
      this.$emit('update:formData', { formName: 'formTwelve', formData: this.model});
    },
    handleCompleteAtr(event) {
      event.preventDefault();
      this.$emit('submit-atr');
    }
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    }
  },
  validations() {
    return {
      formData: {
        firstLineApproval: {
          required,
        },
      },
    };
  },
};
</script>

<style>
</style>