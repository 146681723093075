<template>
  <div class="m-4 px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Search Results</h1>
        <p class="mt-2 text-sm text-gray-700">A list of searched documents</p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button v-if="loaded && !profile?.disableCreateStarter" type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto" @click="navigateNewDoc('starter')">Create ATR</button>
        <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto ml-2" @click="navigateNewDoc('amend')">Create Amendment</button>
        <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto ml-2" @click="navigateNewDoc('leaver')">Create Leaver</button>
      </div>
    </div>
    <div class="mt-8 flex flex-col md:flex-row justify-end">
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Short ID</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Hiring Manager</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Assigned to</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Current stage</th>
                </tr>
              </thead>
              <tbody v-if="atrs.length" class="bg-white">
                <tr v-for="(atr, index) in atrs" :key="atr.docId" :class="index % 2 === 0 ? undefined : 'bg-gray-50'">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-blue-600 hover:underline cursor-pointer" 
                    @click="navigateToAtr(atr, atr.docType)">{{ atr.shortId }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span v-if="docType === 'OFFER'">
                      {{ atr.atr?.formThree?.jobTitle ? atr.atr.formThree.jobTitle : 'No role specified' }}
                    </span>
                    <span v-else>
                      {{ atr.formThree?.jobTitle ? atr.formThree.jobTitle : 'No role specified' }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ atr.requestedByEmail }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ currentAssigneeEmail(atr) }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ findStage(atr.currentStageName, atr.status) }}</td>
                </tr>
              </tbody>
            </table>
            <p v-if="loaded && !atrs.length" class="text-gray-400 text-center p-8"> No Requests to show... </p>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { onBeforeRouteUpdate, useRouter } from 'vue-router'
import { ref, onMounted, onBeforeMount, computed, inject } from 'vue';
import { clearStorage } from '@/storageSave';
import httpClient from '@/httpClient';
import { pathToDocument } from '@/helpers/path-to-document-helper';

const store = useStore();
const router = useRouter();
const atrs = ref([]);
const loaded = ref(false);
const searchString = ref(router.currentRoute.value.params.searchString);
const docType = ref(router.currentRoute.value.params.docType);

const stages = inject('stages');

const findStage = (stageName, status) => {
  let stage;
  const findStage = stages.find((stage) => stage.stageName === stageName);
  if (findStage) return findStage.displayName;
  stage = status === 'DRAFT' ? 'Work in progress' : status;
  return stage;
}
const currentAssigneeEmail = (atr) => atr.currentAssigneeEmail ? 
  atr.currentAssigneeEmail.includes('@') ? 
    atr.currentAssigneeEmail : atr.currentAssignee.includes('@') ? 
      atr.currentAssignee : atr.currentAssigneeEmail 
  : 'Unassigned'
const navigateNewDoc = (docType) => {
  if (docType.toUpperCase() === 'STARTER') {
    clearStorage('atrShortId');
    clearStorage('atrId');
    clearStorage('formData');
  }
  if (docType.toUpperCase() === 'AMEND') {
    clearStorage('amendsFormData');
  }
  if (docType.toUpperCase() === 'LEAVER') {
    store.dispatch('clearAllLeaverFormData');
    clearStorage('leaverShortId');
    clearStorage('leaverForm');
  }
  const routes = {
    starter: '/create-starter/new-atr',
    amend: '/create-amend',
    leaver: '/create-leaver/new-leaver'
  };
  router.push(routes[docType]);
};
const navigateToAtr = (atr,docType) => {
  router.push({path: pathToDocument(atr, docType)})
};

const profile = computed(() => store.getters.getProfile);

const fetchResults = async (docType, searchString) => {
  store.dispatch('setIsLoading', true);
  const response = await httpClient.post('/bff/search', {
    requestedBy: profile.value.email,
    docType,
    docID: searchString
  });
  atrs.value = response.data.body.Items;
  store.dispatch('setIsLoading', false);
}

onBeforeRouteUpdate(async (to) => {
  searchString.value = to.params.searchString;
  docType.value = to.params.docType;
  await fetchResults(docType.value, searchString.value);
})

onBeforeMount(async () => {
  await fetchResults(docType.value, searchString.value);
})

onMounted(() => {
  loaded.value = true;
});

</script>
