<template>
  <form-layout
    :navItemConfig="
      !formData.formOne.saved ? [navItemConfig[0]] : navItemConfig
    "
    :currentNavItem="currentNavItem"
    :formPoint="formPoint"
    :formData="formData"
    :hideAction="hideAction && !currentUserIsAssigned"
    :displayHeader="showApprovalSteps || editable"
    :displayTimeline="showApprovalSteps || editable"
    :displayFooter="showApprovalSteps || editable"
    @set-current-nav-item="setCurrentNavItem"
  >
    <template #pageTitle>Approval to Recruit (ATR)</template>
    <template #header>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">ATR Short ID:</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{ formData.shortId }}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">Job Title:</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{ formData.formThree.jobTitle || "-" }}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">Current Stage:</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{ findStage(formData.currentStageName, formData.status) }}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">Current Assignee:</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{
            formData.formOne?.hiringManagerEmail ??
            formData.currentAssigneeEmail
          }}
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">Doctype:</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {{ formData.docType }}
        </dd>
      </div>
      <div
        class="flex justify-end"
        v-if="profile?.ADMIN || profile?.H2R || profile?.RESOURCING"
      >
        <button
          class="text-center px-4 py-2 rounded-md text-white bg-green-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          @click.prevent="handleReassign"
        >
          Re-assign
        </button>
      </div>
    </template>
    <template #navFooter>
      <button
        class="w-full text-center px-4 py-2 mb-2 rounded-md text-white bg-blue-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
        :class="{ 'bg-red-700 border-red-900': editable }"
        v-if="
          currentUserIsAssigned &&
          !editable &&
          ['starter.notifyHROps', 'starter.notifyBudgetApprover'].includes(
            this.formData.currentStageName
          )
        "
        @click="handleMakeEditable"
      >
        Edit
      </button>
      <button
        v-if="currentUserIsAssigned && !hideAction"
        class="w-full text-center px-4 py-2 rounded-md text-white bg-green-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
        @click="openApprovalModal"
      >
        {{ editable ? "Save Edits" : "Action" }}
      </button>
      <button
        v-if="formData?.currentStageName === 'DRAFT'"
        class="w-full mt-2 text-center px-4 py-2 rounded-md text-white bg-red-700 hover:bg-red-800 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
        @click="showDeleteModal = true"
      >
        Delete Draft
      </button>
      <button
        v-if="
          formData.currentStageName &&
          formData.currentStageName !== 'DRAFT' &&
          (profile?.ADMIN ||
            profile?.H2R ||
            profile?.RESOURCING ||
            currentUserIsAssigned)
          && !hideCancel
        "
        class="w-full mt-2 text-center px-4 py-2 rounded-md text-white bg-red-700 hover:bg-red-800 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
        @click="handleCancelStarter"
      >
        Cancel Starter
      </button>
    </template>
    <template #navFooterWithCancel>
      <button
        v-if="
          formData.currentStageName &&
          formData.currentStageName !== 'DRAFT' &&
          (profile?.ADMIN ||
            profile?.H2R ||
            profile?.RESOURCING ||
            currentUserIsAssigned)
          && !hideCancel
        "
        class="w-full mt-2 text-center px-4 py-2 rounded-md text-white bg-red-700 hover:bg-red-800 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
        @click="handleCancelStarter"
      >
        Cancel Starter
      </button>
    </template>
    <template #main>
      <component
        :is="currentNavItem.component"
        :atrReadyToSubmit="atrReadyToSubmit"
        :configData="configData"
        :formData="formData[currentNavItem.name]"
        :data="formData"
        :disableForm="showApprovalSteps"
        :showApprovalSteps="showApprovalSteps"
        :status="this.formData.status"
        :editable="true"
        @update:formData="handleUpdateFormData"
        @update:reset="handleResetFormData"
        @submit-form="handleSubmitForm"
        @submit-atr="handleSubmitAtr"
      ></component>
      <div class="lg:hidden">
        <button
          class="w-full text-center px-4 py-2 mb-2 rounded-md text-white bg-blue-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          :class="{ 'bg-red-700 border-red-900': editable }"
          v-if="
            currentUserIsAssigned &&
            !editable &&
            ['starter.notifyHROps', 'starter.notifyBudgetApprover'].includes(
              this.formData.currentStageName
            )
          "
          @click="handleMakeEditable"
        >
          Edit
        </button>
        <button
          class="w-full text-center px-4 py-2 rounded-md text-white bg-green-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          @click="openApprovalModal"
          v-if="currentUserIsAssigned && !hideAction"
        >
          {{ editable ? "Save Edits" : "Action" }}
        </button>
      </div>
    </template>
    <template #timeline>
      <activity-feed
        v-if="formData.activityList"
        :activityList="formData?.activityList"
      ></activity-feed>
    </template>
    <template #footer>
      <approval-steps
        v-if="formData.shortId"
        :formData="formData"
        docType="STARTER"
        @comment="fetchSingleAtr(formData.docId)"
      ></approval-steps>
    </template>
  </form-layout>
  <!-- Modal -->
  <ModalWindow v-if="showModal" @close="handleModalClose">
    <template v-slot:icon>
      <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
    </template>
    <template v-slot:title>ATR Approval</template>
    <template v-slot:subtitle
      >What would you like to do with this ATR?</template
    >
    <template v-slot:footer>
      <button
        type="button"
        class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 my-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
        @click="handleApproveAtr"
      >
        Approve
      </button>
      <button
        class="w-full text-center mt-2 px-4 py-2 rounded-md text-white bg-red-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
        @click="handleRejectAtr"
      >
        Reject
      </button>
    </template>
  </ModalWindow>
  <ModalWindow v-if="showDeleteModal" @close="showDeleteModal = false">
    <template v-slot:icon>
      <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
    </template>
    <template v-slot:title>Delete Draft</template>
    <template v-slot:subtitle
      >Are you sure you want to delete this draft?</template
    >
    <template v-slot:footer>
      <button
        type="button"
        class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 my-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
        @click="handleDeleteDraft"
      >
        Delete
      </button>
      <button
        class="w-full text-center mt-2 px-4 py-2 rounded-md text-white bg-red-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
        @click="showDeleteModal = false"
      >
        Cancel
      </button>
    </template>
  </ModalWindow>
</template>
<script>
import { useStore } from "vuex";
import httpClient from "@/httpClient";
import { getStorage, saveStorage } from "@/storageSave";
import { getChangedValues } from "@/formFunctions";
import { CheckIcon } from "@heroicons/vue/outline";
import { Auth } from "aws-amplify";
import formLayout from "@/layouts/formLayout.vue";
import ActivityFeed from "@/components/activityFeed.vue";
import ApprovalSteps from "./create-starter/approvalSteps.vue";
import firstSecondApproval from "./approvalWorkflowForms/firstSecondApproval.vue";
import formOne from "./create-starter/formOne.vue";
import formTwo from "./create-starter/formTwo.vue";
import formThree from "./create-starter/formThree.vue";
import formFour from "./create-starter/formFour.vue";
import formFive from "./create-starter/formFive.vue";
import formSix from "./create-starter/formSix.vue";
import formSeven from "./create-starter/formSeven.vue";
import formEight from "./create-starter/formEight.vue";
import formNine from "./create-starter/formNine.vue";
import formTen from "./create-starter/formTen.vue";
import formEleven from "./create-starter/formEleven.vue";
import formTwelve from "./create-starter/formTwelve.vue";
import ModalWindow from "@/components/ModalWindow.vue";
import outlineCreditCard from "./icons/outlineCreditCard.vue";
import outlineKey from "./icons/outlineKey.vue";
import outlineUserGroup from "./icons/outlineUserGroup.vue";
import outlineViewGridAdd from "./icons/outlineViewGridAdd.vue";
export default {
  name: "createStarter",
  components: {
    CheckIcon,
    formLayout,
    ActivityFeed,
    ApprovalSteps,
    firstSecondApproval,
    formOne,
    formTwo,
    formThree,
    formFour,
    formFive,
    formSix,
    formSeven,
    formEight,
    formNine,
    formTen,
    formEleven,
    formTwelve,
    ModalWindow,
    outlineCreditCard,
    outlineKey,
    outlineUserGroup,
    outlineViewGridAdd,
  },
  data() {
    return {
      editable: false,
      showDeleteModal: false,
      store: useStore(),
      formPoint: 0,
      showModal: false,
      atrId: null,
      atrShortId: null,
      configData: {},
      oldFormData: {},
      formData: {
        formOne: {
          hiringManagerName: "",
          hiringManagerEmail: "",
          hiringManagerId: "",
          secondary: "",
          secondaryName: "",
          secondaryEmail: "",
          secondaryId: "",
          saved: false,
        },
        formTwo: {
          recruitmentReason: "",
          inBudget: "",
          saved: false,
          employee_jobTitle: "",
          employee_id: "",
          employee_name: "",
          recovered: "",
        },
        formThree: {
          jobTitle: "",
          numberOfPositions: "",
          roleLocationSite: "",
          roleLocationPostcode: "",
          employmentContract: "",
          duration: "",
          secondment: "",
          saved: false,
        },
        formFour: {
          businesscontact: null,
          employedin: "",
          division: "",
          sector: "",
          assigned: "",
          saved: false,
          activityCode: "",
        },
        formFive: {
          hoursPerWeek: "",
          weeksPerYear: "",
          shiftPattern: [],
          additionalInformation: "",
          saved: false,
        },
        formSix: {
          contractType: "",
          contractLevel: "",
          salaryBasis: "",
          minimumPayRate: "",
          minimumPayRateCurrency: "GBP",
          maximumPayRate: "",
          maximumPayRateCurrency: "GBP",
          additionalAllowances: "",
          additionalAllowancesInformation: [
            {
              additionalAllowancesTitle: "",
              additionalAllowancesInformation: "",
              additionalAllowancesCurrency: "GBP",
            },
          ],
          additionalAllowancesTitle: "",
          payrollFrequency: "",
          saved: false,
        },
        formSeven: {
          recruitmentTeam: "",
          chosen_not: null,
          advertise: "selected",
          advertiseOther: "",
          channels: [],
          channelsOther: "",
          recruitmentShortlist: "",
          saved: false,
        },
        formEight: {
          disclosureCheckRequired: "",
          disclosureSchemeEnglandWales: "",
          disclosureSchemeScotland: "",
          childSafeguardingRequired: "",
          saved: false,
          certificate: "",
        },
        formNine: {
          securityRole: "",
          securitySIA: "",
          securityCheck: "",
          securityPreliminary: "",
          securityBSVR: "",
          securityGovernment: "",
          securityDWP: "",
          securityMobile: "",
          securityBefore: "",
          saved: false,
          counterTerrorism: "",
          securityHidden: "",
          developedVetting: "",
        },
        formTen: {
          highwayWorker: "",
          mobileWorker: "",
          hybridWorker: "",
          collectiveBargaining: "",
          collectiveBargainingText: "",
          saved: false,
        },
        formEleven: {
          files: [],
          saved: false,
        },
        formTwelve: {
          firstLineApproval: "",
          saved: false,
        },
      },
      navItemConfig: [
        {
          formPoint: 0,
          name: "formOne",
          text: "Hiring Manager",
          component: "formOne",
          icon: "UserAddIcon",
        },
        {
          formPoint: 1,
          name: "formTwo",
          text: "Recruitment Reason",
          component: "formTwo",
          icon: "TicketIcon",
        },
        {
          formPoint: 2,
          name: "formThree",
          text: "Job Details",
          component: "formThree",
          icon: "ViewListIcon",
        },
        {
          formPoint: 3,
          name: "formFour",
          text: "Organisation Details",
          component: "formFour",
          icon: "UserGroupIcon",
        },
        {
          formPoint: 4,
          name: "formFive",
          text: "Hours of Work",
          component: "formFive",
          icon: "ClockIcon",
        },
        {
          formPoint: 5,
          name: "formSix",
          text: "Contract Details",
          component: "formSix",
          icon: "DocumentTextIcon",
        },
        {
          formPoint: 6,
          name: "formSeven",
          text: "Advertising Requirements",
          component: "formSeven",
          icon: "NewspaperIcon",
        },
        {
          formPoint: 7,
          name: "formEight",
          text: "Pre Employment Checks",
          component: "formEight",
          icon: "ClipboardListIcon",
        },
        {
          formPoint: 8,
          name: "formNine",
          text: "Security",
          component: "formNine",
          icon: "LockClosedIcon",
        },
        {
          formPoint: 9,
          name: "formTen",
          text: "Specialist Roles",
          component: "formTen",
          icon: "UserCircleIcon",
        },
        {
          formPoint: 10,
          name: "formEleven",
          text: "Document Upload",
          component: "formEleven",
          icon: "DocumentAddIcon",
        },
        {
          formPoint: 11,
          name: "formTwelve",
          text: "Next Steps",
          component: "formTwelve",
          icon: "ArrowNarrowRightIcon",
        },
        {
          formPoint: 12,
          name: "formTwelve",
          text: "First & Second Line Approval",
          component: "firstSecondApproval",
          icon: "DocumentTextIcon",
        },
      ],
    };
  },
  inject: ["stages"],
  methods: {
    openApprovalModal() {
      this.showModal = true;
    },
    async handleCancelStarter() {
      try {
        this.store.dispatch("setIsLoading", true);

        const id = this.formData.docId
          ? this.formData.docId
          : this.$router.currentRoute.value.params.id;
        const requestedBy = this.profile?.userID;
        const docOwner = this.formData.requestedBy;
        await httpClient.post("/bff/cancel-request", {
          id,
          requestedBy,
          docOwner,
        });
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "success",
          title: "Success!",
          message: "This starter request has been cancelled.",
        });
        this.$store.dispatch("setIsLoading", false);
        this.$router.push("/");
      } catch (err) {
        this.$store.dispatch("setIsLoading", false);
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "error",
          title: "Error!",
          message: "There was a problem cancelling the starter request.",
        });
      }
    },
    handleModalClose() {
      this.showModal = false;
    },
    handleApproveAtr() {
      this.$router.push("/approve-workflow/1");
    },
    handleRejectAtr() {
      this.$router.push("/approve-workflow/2");
    },
    handleReassign() {
      const id = this.formData.docId;
      const docOwner = this.formData.requestedBy;
      this.$router.push(`/re-assign/${id}/${docOwner}`);
    },
    handleMakeEditable() {
      this.oldFormData = JSON.parse(JSON.stringify(this.formData));
      this.editable = !this.editable;
      // show notification
      this.store.dispatch("setNotificationAll", {
        visible: true,
        type: "info",
        title: "Warning!",
        message: "Remember to click \"Next\" at the bottom of each page to save your changes.",
      });
    },
    handleUpdateFormData({ formName, formData }) {
      this.formData[formName] = formData;
      saveStorage("formData", JSON.stringify(this.formData));
    },
    findStage(stageName, status) {
      let stage;
      const findStage = this.stages.find(
        (stage) => stage.stageName === stageName
      );
      if (findStage) return findStage.displayName;
      stage = status === "DRAFT" ? "Work in progress" : status;
      return stage;
    },
    setCurrentNavItem(formPoint) {
      this.formPoint = formPoint;
    },
    // A request to submit a form
    async handleSubmitForm({ name, formData }) {
      try {
        this.store.dispatch("setIsLoading", true);
        // set saved attribute
        this.formData[name].saved = true;

        let changedValues = "";
        // get any values changed by a 2nd line approver so they can be saved in the activity log
        if (
          this.editable &&
          ["starter.notifyHROps", "starter.notifyBudgetApprover"].includes(
            this.formData.currentStageName
          )
        ) {
          changedValues = getChangedValues(this.oldFormData, this.formData);
          this.oldFormData = JSON.parse(JSON.stringify(this.formData));
        }

        // Make the api appropriate API request for the form being submitted
        const payload = {
          formData: { ...formData },
          requestedBy: this.formData?.requestedBy || this.profile.userID,
          id: this.atrId,
          stage: this.formData.currentStageName,
        };
        if (changedValues.length) {
          payload.changes = JSON.stringify(changedValues);
          payload.edited = true;
        }
        const response = await httpClient.post(`/bff/starter/${name}`, payload);
        // Get short id and long id to store in storage
        const body = response.data.body;

        this.formData = body.Item;
        this.atrShortId = body.Item.shortId;
        this.atrId = body.Item.docId;
        saveStorage("atrShortId", this.atrShortId);
        saveStorage("atrId", this.atrId);
        saveStorage("formData", this.formData);
        // show notification
        this.store.dispatch("setNotificationAll", {
          visible: true,
          type: "success",
          title: "SAVED!",
          message: "The form has been saved.",
        });
        if (this.formPoint < this.navItemConfig.length - 2) {
          this.formPoint++;
        }
        saveStorage("formData", JSON.stringify(this.formData));
        await this.store.dispatch("setIsLoading", false);
      } catch (err) {
        console.log(err);
        // set saved attribute
        this.formData[name].saved = false;
        this.store.dispatch("setNotificationAll", {
          visible: true,
          type: "error",
          title: "Error!",
          message: "There was a problem submitting the form",
        });
        this.store.dispatch("setIsLoading", false);
      }
    },
    resetMessage() {
      this.$store.dispatch("resetMessage");
    },
    // function to get whether or not a form has been saved
    getFormSavedStatus(formName) {
      const cache = getStorage("formData");
      if (cache) {
        const cachedData = JSON.parse(cache);
        return cachedData[formName].saved;
      }
      return this.formData[formName]?.saved;
    },
    // A request to submit the atr
    async handleSubmitAtr() {
      const payload = {
        requestedBy: this.formData.requestedBy,
        id: this.atrId,
      };
      try {
        await httpClient.post("/bff/starter/complete", payload);
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "success",
          title: "Success!",
          message: "Your ATR has been submitted.",
        });
        this.$router.push("/");
      } catch (err) {
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "error",
          title: "Error!",
          message: "There was a problem submitting this ATR.",
        });
      }
    },
    // function to fetch an atr and save it to local storage
    async fetchSingleAtr(id) {
      this.$store.dispatch("setIsLoading", true);
      const requestedBy = this.$route.params.docOwner;
      const payload = { requestedBy, id };
      const response = await httpClient.post("/bff/get-document", payload);
      const body = response.data.body;
      this.formData = body.Item;
      saveStorage("formData", JSON.stringify(body.Item));
      saveStorage("atrShortId", body.Item.shortId);
      saveStorage("atrId", body.Item.docId);
      this.$store.dispatch("setIsLoading", false);
    },
    // handle an event to reset a form
    handleResetFormData(formName) {
      if (this.editable) {
        this.formData[formName] = JSON.parse(
          JSON.stringify(this.oldFormData[formName])
        );
      } else {
        const keys = Object.keys(this.formData[formName]);
        keys.forEach((key) => {
          if (typeof this.formData[formName][key] === "string") {
            this.formData[formName][key] = "";
          } else if (typeof this.formData[formName][key] === "number") {
            this.formData[formName][key] = null;
          } else if (Array.isArray(this.formData[formName][key])) {
            this.formData[formName][key] = [];
          } else if (typeof this.formData[formName][key] === "object") {
            const nestedKeys = this.formData[formName][key];
            nestedKeys.forEach((nk) => (this.formData[formName][key][nk] = ""));
          }
        });
      }
      saveStorage("formData", JSON.stringify(this.formData));
    },
    // Fetch config data
    async fetchConfigData() {
      const response = await httpClient.post("/config/all", {
        requestedBy: "something",
      });
      this.configData = response.data.body;
    },
    async handleDeleteDraft() {
      try {
        this.$store.dispatch("setIsLoading", true);
        const id = this.atrId
          ? this.atrId
          : this.$router.currentRoute.value.params.id;
        const requestedBy = this.profile?.userID;
        const docOwner = this.formData.requestedBy;
        await httpClient.post("/bff/delete-draft", {
          id,
          requestedBy,
          docOwner,
        });
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "success",
          title: "Success!",
          message: "This ATR has been deleted.",
        });
        this.$store.dispatch("setIsLoading", false);
        this.$router.push("/");
      } catch (err) {
        this.$store.dispatch("setIsLoading", false);
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "error",
          title: "Error!",
          message: "There was a problem deleting the ATR.",
        });
      }
    },
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile;
    },
    route() {
      return this.$route;
    },
    currentNavItem() {
      return (
        this.navItemConfig.find((item) => item.formPoint === this.formPoint) ??
        this.navItemConfig[0]
      );
    },
    usersEmail() {
      return Auth?.user.signInUserSession.idToken.payload.email;
    },
    atrReadyToSubmit() {
      // Check all form entries, if one is not saved return false
      if (
        Object.keys(this.formData)
          .filter((i) => i.startsWith("form"))
          .find((item) => !this.formData[item]?.saved)
      )
        return false;
      return true;
    },
    showApprovalSteps() {
      if (this.editable) {
        return false;
      } else if (this.formPoint === 12) {
        return true;
      } else if (
        this.formData?.status === undefined ||
        this.formData?.status === "DRAFT"
      ) {
        return false;
      } else {
        return true;
      }
    },
    hideAction() {
      return [
        "starter.notifyMoreNotifyHROps",
        "starter.notifyMoreInfoBudgetApprover",
        "starter.notifyMoreInfoBudgetApproval",
        "starter.notifyRecruitment",
        "starter.complete",
      ].includes(this.formData?.currentStageName);
    },
    hideCancel() {
      return [
        "starter.notifyMoreNotifyHROps",
        "starter.notifyMoreInfoBudgetApprover",
        "starter.notifyMoreInfoBudgetApproval",
        "starter.complete",
        "starter.cancelled",
      ].includes(this.formData?.currentStageName);
    },
    currentUserIsAssigned() {
      return (
        this.formData.currentAssignee?.toUpperCase() ===
        Auth.user.signInUserSession.idToken.payload.identities[0].userId.split(
          "@"
        )[0].toUpperCase()
      );
    },
  },
  async created() {
    if (this.$route.name === "View ATR") {
      const id = this.$route.params.id;
      await this.fetchSingleAtr(id);
    } else {
      // get cached formData if exists
      const cachedFormData = getStorage("formData");
      if (cachedFormData) this.formData = JSON.parse(cachedFormData);
    }
    await this.fetchConfigData();
    // short and long ids if they exist
    const atrId = getStorage("atrId");
    const atrShortId = getStorage("atrShortId");
    if (atrId) this.atrId = atrId;
    if (atrId) this.atrShortId = atrShortId;
  },
  watch: {
    route(newVal) {
      if (newVal.name === "Create Starter") {
        this.formData = {
          formOne: {
            hiringManagerName: "",
            hiringManagerEmail: "",
            employeeName: "",
            employeeEmail: "",
            saved: false,
          },
          formTwo: {
            recruitmentReason: "",
            inBudget: "",
            saved: false,
            employee_jobTitle: "",
            employee_id: "",
            employee_name: "",
            recovered: "",
          },
          formThree: {
            jobTitle: "",
            numberOfPositions: "",
            roleLocationSite: "",
            roleLocationPostcode: "",
            employmentContract: "",
            duration: "",
            secondment: "",
            saved: false,
          },
          formFour: {
            businesscontact: null,
            employedin: "",
            division: "",
            assigned: "",
            saved: false,
          },
          formFive: {
            hoursPerWeek: "",
            weeksPerYear: "",
            shiftPattern: [],
            additionalInformation: "",
            saved: false,
          },
          formSix: {
            contractType: "",
            contractLevel: "",
            salaryBasis: "",
            minimumPayRate: "",
            minimumPayRateCurrency: "GBP",
            maximumPayRate: "",
            maximumPayRateCurrency: "GBP",
            additionalAllowances: "",
            additionalAllowancesInformation: [
              {
                additionalAllowancesTitle: "",
                additionalAllowancesInformation: "",
              },
            ],
            additionalAllowancesTitle: "",
            payrollFrequency: "",
            saved: false,
          },
          formSeven: {
            recruitmentTeam: "",
            chosen_not: null,
            advertise: "selected",
            channels: [],
            channelsOther: "",
            recruitmentShortlist: "",
            saved: false,
          },
          formEight: {
            disclosureCheckRequired: "",
            disclosureSchemeEnglandWales: "",
            disclosureSchemeScotland: "",
            childSafeguardingRequired: "",
            saved: false,
            certificate: "",
          },
          formNine: {
            securityRole: "",
            securitySIA: "",
            securityCheck: "",
            securityPreliminary: "",
            securityBSVR: "",
            securityGovernment: "",
            securityDWP: "",
            securityMobile: "",
            securityBefore: "",
            saved: false,
            counterTerrorism: "",
            securityHidden: "",
            developedVetting: "",
          },
          formTen: {
            highwayWorker: "",
            mobileWorker: "",
            hybridWorker: "",
            collectiveBargaining: "",
            collectiveBargainingText: "",
            saved: false,
          },
          formEleven: {
            files: [],
            saved: false,
          },
          formTwelve: {
            firstLineApproval: "",
            saved: false,
          },
        };
      }
    },
  },
};
</script>
