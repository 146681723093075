<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Reason For Leaving
          </h3>
        </div>
        <!-- Reason for leaving -->
        <div class="col-span-6 flex">
          <div>
            <label
              for="reason"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Reason for leaving EQUANS: *
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <select
                id="reason"
                name="reason"
                autocomplete="reason-name"
                v-model="formData.reason"
                class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                :class="{
                  'bg-gray-200 border-none cursor-not-allowed': disableForm,
                  'error-border': v$.formData.reason.$error,
                  'border-red-500': v$.formData.reason.$error,
                }"
                :disabled="disableForm"
                @blur="v$.formData.reason.$touch()"
              >
                <option
                  v-for="item in reasonsForLeaving"
                  :key="item.id"
                  :value="item.name"
                >
                  {{ item.name }}
                </option>
              </select>
              <span v-if="v$.formData.reason.$error" class="text-red-500">
                * {{ v$.formData.reason.$errors[0].$message }}
              </span>
            </div>
          </div>
          <div v-if="formData.reason" class="mt-1 ml-4 sm:mt-0 sm:col-span-2">
            <label
              for="reasonMore"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >Reason for {{ formData.reason }} *</label
            >
            <select
              id="reasonMore"
              name="reasonMore"
              autocomplete="reason-name"
              v-model="formData.reasonResignation"
              class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              :class="{
                'bg-gray-200 border-none cursor-not-allowed': disableForm,
                'error-border': v$.formData.reasonResignation.$error,
                'border-red-500': v$.formData.reasonResignation.$error,
              }"
              :disabled="disableForm"
              @blur="v$.formData.reasonResignation.$touch()"
            >
              <option
                v-for="item in reason.Items"
                :key="item.id"
                :value="item.name"
              >
                {{ item.name }}
              </option>
            </select>
            <span
              v-if="v$.formData.reasonResignation.$error"
              class="text-red-500"
            >
              * {{ v$.formData.reasonResignation.$errors[0].$message }}
            </span>
          </div>
        </div>

        <!-- End of reason for leavong -->

        <!-- Comments -->
        <div>
          <label for="comments" class="block text-sm font-medium text-gray-700"
            >Comments</label
          >
          <textarea
            v-model="formData.comments"
            id="reason-comments"
            name="comments"
            rows="3"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
          />
        </div>
        <!-- End comments -->
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" v-if="!disableForm">
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400"
          @click.prevent="handleSave"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  props: ["disableForm"],
  data() {
    return {
      v$: useVuelidate(),
      reasonsForLeaving: [
        {
          name: "Dismissal",
          id: "1",
          Items: [
            {
              name: "Disciplinary",
              id: "1-1",
            },
            {
              name: "Fixed Term Contr.",
              id: "1-2",
            },
            {
              name: "Ill health",
              id: "1-3",
            },
            {
              name: "Mass (whole unit)",
              id: "1-4",
            },
            {
              name: "Other",
              id: "1-5",
            },
            {
              name: "Redundancy",
              id: "1-6",
            },
            {
              name: "Redundancy Settlement",
              id: "1-7",
            },
            {
              name: "Settlement Agrmnt",
              id: "1-8",
            },
            {
              name: "Unsuitable for job",
              id: "1-9",
            },
            {
              name: "Work Permit Expiry",
              id: "1-10",
            },
          ],
        },
        {
          name: "Resignation",
          id: "3",
          Items: [
            {
              name: "Accident",
              id: "3-1",
            },
            {
              name: "Another Job",
              id: "3-2",
            },
            {
              name: "Career Break",
              id: "3-3",
            },
            {
              name: "Career Progress.",
              id: "3-4",
            },
            {
              name: "Domestic Resp.",
              id: "3-5",
            },
            {
              name: "Illness",
              id: "3-6",
            },
            {
              name: "Job Dissatisf.",
              id: "3-7",
            },
            {
              name: "Maternity",
              id: "3-8",
            },
            {
              name: "No reason given",
              id: "3-9",
            },
            {
              name: "Parental leave",
              id: "3-10",
            },
            {
              name: "Paternity",
              id: "3-11",
            },
            {
              name: "Pay and Benefits",
              id: "3-12",
            },
            {
              name: "Personal Reasons",
              id: "3-13",
            },
            {
              name: "Prefer not to say",
              id: "3-14",
            },
            {
              name: "Travel Difficult",
              id: "3-15",
            },
            {
              name: "Return to Study",
              id: "3-16",
            },
            {
              name: "Work Hours Unac.",
              id: "3-17",
            },
            {
              name: "Work Rel. Unsat.",
              id: "3-18",
            },
          ],
        },
        {
          name: "Other",
          id: "2",
          Items: [
            {
              name: "Death in Service",
              id: "2-1",
            },
            {
              name: "Go Live Late Leaver",
              id: "2-2",
            },
            {
              name: "Group Mobility in UK",
              id: "2-3",
            },
            {
              name: "Grp Mobility Overseas",
              id: "2-4",
            },
            {
              name: "Other",
              id: "2-5",
            },
            {
              name: "Retirement",
              id: "2-6",
            },
            {
              name: "Settlement",
              id: "2-7",
            },
            {
              name: "TUPE",
              id: "2-8",
            },
          ],
        },
      ],
    };
  },
  computed: {
    formData() {
      return this.$store.getters.getLeaverFormData("formThree");
    },
    reason() {
      return this.reasonsForLeaving.find(
        (reason) => reason.name === this.formData.reason
      );
    },
  },
  methods: {
    handleSave() {
      this.$emit("submit-form", { name: "formThree", formData: this.formData });
    },
  },
  validations() {
    return {
      formData: {
        reason: { required },
        reasonResignation: { required },
      },
    };
  },
};
</script>
