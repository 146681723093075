<template>
  <formLayout
    :navItemConfig="navItems"
    :currentNavItem="currentNavItem"
    :formPoint="formPoint"
    formName="leaverForm"
    :displayHeader="formData.status && formData.status !== 'DRAFT'"
    :displayTimeline="disableForm || editable"
    :displayFooter="disableForm || editable"
    :printMode="inPrintMode"
    :formData='formData'
    @set-current-nav-item="setCurrentNavItem"
  >
    <template #pageTitle>Leaver notification</template>
    <template #header>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Name of employee: </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.formOne?.name || '-' }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Stage:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ currentStage?.displayName }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Assignee:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.currentAssigneeEmail }}</dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Status:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ formData.status }}</dd>
      </div>
      <div class="flex justify-end" v-if="profile?.ADMIN || profile?.H2R || profile?.RESOURCING">
        <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-md
            border
            border-transparent
            bg-green-700
            hover:bg-green-800
            hover:underline
            px-4
            py-2
            text-sm
            font-medium
            text-white
            shadow-sm
            hover:bg-green-800
            focus:outline-none
            focus:ring-2
            focus:ring-indigo-500
            focus:ring-offset-2
            sm:w-auto
          " 
          @click="handleReassign"
        >Re-assign</button>
      </div>
    </template>
    <template #pageSubtitle>
      <b
        >*Please ensure that all mandatory fields are completed before submitting for approval</b
      ><br />
      <b
        >*For compatible browsers please review this link. If your browser is
        not compatible it may effect the text you see within multi line text
        boxes and /or the speed of the people search.</b
      >
    </template>
    <template #navFooter>
      <button
        class="
          w-full
          text-center
          px-4
          py-2
          mb-2
          rounded-md
          text-white
          bg-blue-700
          hover:underline
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-cyan-500
          bg-no-repeat
        "
        :class="{'bg-red-700 border-red-900': editable}"
          v-if="currentUserIsAssigned && ['leaver.notifyHROps'].includes(this.formData.currentStageName) && !editable"
        @click="handleMakeEditable"
      >
        Edit
      </button>
      <button
        v-if="currentUserIsAssigned && (disableForm || editable) && formData.currentStageName === 'leaver.notifyH2RNewLeaver'"
        class="
          w-full
          text-center
          px-4
          py-2
          rounded-md
          text-white
          bg-green-700
          hover:bg-green-800
          hover:underline
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-cyan-500
          bg-no-repeat
        "
        @click="handleAssign"
      >
        Assign Admin
      </button>
      <button
        v-if="currentUserIsAssigned && (disableForm || editable) && formData.currentStageName === 'leaver.notifyHROps'"
        class="
          w-full
          text-center
          px-4
          py-2
          rounded-md
          text-white
          bg-green-700
          hover:bg-green-800
          hover:underline
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-cyan-500
          bg-no-repeat
        "
        @click="handleModalOpen"
      >
        {{ editable ? 'Save Edits' : 'Action' }}
      </button>
      <button
        v-if="(currentUserIsAssigned && (disableForm || editable) && formData.currentStageName === 'leaver.notifyH2RAdminAssigned') ||
        (formData.currentStageName === 'leaver.notifyH2RAdminAssigned' && currentNavItem?.name === 'formFive')"
        class="
          w-full
          text-center
          px-4
          py-2
          rounded-md
          text-white
          bg-green-700
          hover:bg-green-800
          hover:underline
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-cyan-500
          bg-no-repeat
        "
        @click="handleConfirmLeaver"
      >
        Confirm Leaver
      </button>
      <button
        v-if="currentUserIsAssigned && disableForm && formData.currentStageName === 'leaver.notifyHMMoreInfoNeeded'"
        class="
          w-full
          text-center
          px-4
          py-2
          rounded-md
          text-white
          bg-green-700
          hover:bg-green-800
          hover:underline
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-cyan-500
          bg-no-repeat
        "
        @click="handleRespondMoreInfo"
      >
        Action
      </button>
      <button
        v-if="
          currentUserIsDirectlyAssigned &&
          disableForm &&
          formData.currentStageName === 'leaver.notifyH2R'
        "
        class="
          w-full
          text-center
          px-4
          py-2
          rounded-md
          text-white
          bg-green-700
          hover:bg-green-800
          hover:underline
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-cyan-500
          bg-no-repeat
        "
        @click="handleCompleteRequest"
      >
        Complete Request
      </button>
      <button
        v-if="
          (formData.currentStageName && formData.currentStageName !== 'DRAFT') &&
          (profile?.ADMIN || profile?.H2R || profile?.RESOURCING || currentUserIsAssigned) &&
          formData.currentStageName !== 'leaver.notifyH2R' &&
          formData.currentStageName !== 'leaver.complete' 
        "
        class="
          w-full
          mt-2
          text-center
          px-4
          py-2
          rounded-md
          text-white
          bg-red-700
          hover:bg-red-800
          hover:underline
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-cyan-500
          bg-no-repeat
        "
        @click="handleCancelLeaver"
      >
        Cancel leaver
      </button>
      <button
         v-if="formData?.currentStageName === 'DRAFT'"
        class="
          w-full
          mt-2
          text-center
          px-4
          py-2
          rounded-md
          text-white
          bg-red-700
          hover:bg-red-800
          hover:underline
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-cyan-500
          bg-no-repeat
        "
        @click="showDeleteModal = true"
      >
        Delete Draft
      </button>
      <button
        v-if="canPrint"
        class="w-full text-center mt-2 px-4 py-2 rounded-md text-white bg-blue-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
        @click="print"
      >
        Print/Save to PDF
      </button>

    </template>
    <template #main>
      <component
        :is="currentNavItem?.component"
        :disableForm="disableForm"
        :configItems="configItems"
        :data="formData"
        :reasonResignation="formData?.formThree?.reasonResignation ?? ''"
        @submit-form="handleSubmitForm"
        @submit-leaver="handleSubmitLeaver"
      />
    </template>
    <template #printArea>
        <div v-for="comp in navItems" v-bind:key="comp.name">
          <component
            :is="comp?.component"
            :disableForm="true"
            :configItems="configItems"
            :data="formData"
          />
        </div>
      </template>
    <template #timeline>
      <activity-feed v-if="formData.activityList" :activityList="formData?.activityList"></activity-feed>
    </template>
    <template #footer>
      <div class="bg-white rounded-lg p-4 w-full">
        <LeaverHROpsApprovalChat v-if="formData.shortId" :formData="formData" docType="LEAVER" />
      </div>
    </template>
  </formLayout>
    <!-- Modal -->
  <ModalWindow v-if="showModal" @close="handleModalClose">
    <template v-slot:icon>
      <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
    </template>
    <template v-slot:title>Leaver Approval</template>
    <template v-slot:subtitle
      >What would you like to do with this Leaver?</template
    >
    <template v-slot:footer>
      <button
        type="button"
        class="
          inline-flex
          justify-center
          w-full
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          my-2
          bg-green-600
          text-base
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          sm:text-sm
        "
        @click="handleHROpsApproval"
      >
        Approve
      </button>
      <button
        class="
          w-full
          text-center
          mt-2
          px-4
          py-2
          rounded-md
          text-white
          bg-red-700
          hover:underline
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-cyan-500
          bg-no-repeat
        "
        @click="handleRejectLeaver"
      >
        Reject
      </button>
    </template>
  </ModalWindow>
  <ModalWindow v-if="showDeleteModal" @close="showDeleteModal = false">
    <template v-slot:icon>
      <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
    </template>
    <template v-slot:title>Delete Draft</template>
    <template v-slot:subtitle
      >Are you sure you want to delete this draft?</template
    >
    <template v-slot:footer>
      <button
        type="button"
        class="
          inline-flex
          justify-center
          w-full
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          my-2
          bg-green-600
          text-base
          font-medium
          text-white
          hover:bg-green-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
          sm:text-sm
        "
        @click="handleDeleteDraft"
      >
        Delete
      </button>
      <button
        class="
          w-full
          text-center
          mt-2
          px-4
          py-2
          rounded-md
          text-white
          bg-red-700
          hover:underline
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-cyan-500
          bg-no-repeat
        "
        @click="showDeleteModal = false"
      >
        Cancel
      </button>
      
    </template>
  </ModalWindow>
</template>

<script>
import activityFeed from "@/components/activityFeed.vue";
import approvalSteps from "@/components/approvalMessages.vue";
import httpClient from '@/httpClient';
import formLayout from "@/layouts/formLayout.vue";
import { getChangedValues } from '@/formFunctions';
import formOne from "@/components/create-leaver/formOne.vue";
import formTwo from "@/components/create-leaver/formTwo.vue";
import formThree from "@/components/create-leaver/formThree.vue";
import formFour from "@/components/create-leaver/formFour.vue";
import formFive from "@/components/create-leaver/formFive.vue";
import formSix from "@/components/create-leaver/formSix.vue";
import formSeven from "@/components/create-leaver/formSeven.vue";
import formEight from "@/components/create-leaver/formEight.vue"
import formNine from "@/components/create-leaver/formNine.vue";
import formTen from "@/components/create-leaver/formTen.vue";
import LeaverApprovals from '@/components/approvalWorkflowForms/LeaverApprovals.vue'
import LeaverHROpsApprovalChat from "@/components/LeaverHROpsApprovalChat.vue";
import ModalWindow from "@/components/ModalWindow.vue";
import { CheckIcon } from "@heroicons/vue/outline"
import { getStorage, saveStorage } from "@/storageSave";
import { shallowRef } from '@vue/reactivity';

export default {
  inject: ['stages'],
  components: {
    activityFeed,
    approvalSteps,
    CheckIcon,
    formLayout,
    LeaverHROpsApprovalChat,
    LeaverApprovals,
    ModalWindow
  },
  data() {
    return {
      editable: false,
      oldFormData: {},
      showDeleteModal: false,
      inPrintMode: false,
      navItemConfig: shallowRef([
        {
          formPoint: 0,
          name: "formNine",
          text: "Final Payments & Deductions",
          component: formNine,
          icon: "DocumentTextIcon",
        },
        {
          formPoint: 1,
          name: "formOne",
          text: "Your Details",
          component: formOne,
          icon: "UserCircleIcon",
        },
        {
          formPoint: 2,
          name: "formTwo",
          text: "Employee Details",
          component: formTwo,
          icon: "UserIcon",
        },
        {
          formPoint: 3,
          name: "formThree",
          text: "Reason For Leaving",
          component: formThree,
          icon: "LogoutIcon",
        },
        {
          formPoint: 4,
          name: "formSix",
          text: "Resignation",
          component: formSix,
          icon: "DocumentTextIcon",
        },
        {
          formPoint: 5,
          name: "formFour",
          text: "Re-Employment",
          component: formFour,
          icon: "EmojiHappyIcon",
        },
        {
          formPoint: 6,
          name: "formFive",
          text: "Additional Information",
          component: formFive,
          icon: "DocumentTextIcon",
        },
        {
          formPoint: 7,
          name: 'formSeven',
          text: 'Reporting & Process Continuity',
          component: formSeven,
          icon: "DocumentTextIcon"
        },
        {
          formPoint: 8,
          name: 'formEight',
          text: 'Annual Leave',
          component: formEight,
          icon: "DocumentTextIcon"
        },
        {
          formPoint: 9,
          name: "formTen",
          text: "Next Steps",
          component: formTen,
          icon: "ArrowNarrowRightIcon"
        },
        {
          formPoint: 10,
          name: "formTen",
          text: "HROps Approval",
          component: LeaverApprovals,
          icon: "DocumentTextIcon"
        }
      ]),
      formPoint: 0,
      leaverForm: [],
      configItems: {},
      showModal: false,
      formData: this.$store.getters.getLeaver
    };
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile;
    },
    leaverId() {
      return this.$store.getters.getLeaverId || null;
    },
    currentNavItem() {
      return this.navItems?.find((item) => item.formPoint === this.formPoint);
    },
    formData2() {
      return this.$store.getters.getLeaver;
    },
    disableForm() {
      if (this.editable) {
        return false;
      } 
      if (this.formData.status === 'DRAFT' && this.currentNavItem?.text === 'HROps Approval') return true;
      if (['leaver.notifyH2RAdminAssigned', 'leaver.notifyHiringManagerPayments'].includes(this.currentStage?.stageName)) {
        if (this.currentNavItem?.name === 'formFive') return false;
      }
      if (this.currentStage?.stageName === 'leaver.notifyHiringManagerPayments') {
        if (this.currentNavItem?.name === 'formNine') return false;
        else return true
      } else {
        return this.formData.status !== undefined && this.formData.status !== 'DRAFT';
      }
    },
    showNextSteps() {
      return this.formData.status === undefined || this.formData.status === 'DRAFT';
    },
    currentUserIsAssigned() {
      return this.profile?.H2R || this.profile?.ADMIN || this.currentUserIsDirectlyAssigned;
    },
    currentUserIsDirectlyAssigned() {
      return this.formData?.currentAssignee?.toUpperCase() === this.profile?.userID.toUpperCase()
    },
    windowWidth() {
      return window.innerWidth;
    },
    currentStage() {
      return this.stages.find((stage) => this.formData?.currentStageName === stage.stageName)
    },
    navItems() {
      let filtered = this.navItemConfig;
      if (!this.showNextSteps) {
          filtered = filtered.filter((item) => item?.text !== 'Next Steps');
      }
      if ((!this.formData.status || this.formData.status === 'DRAFT') || [
        'leaver.notifyH2RNewLeaver',
        'leaver.notifyH2RAdminAssigned',
        'leaver.notifyHMMoreInfoNeeded'
      ].includes(this.currentStage?.stageName) && this.navItemConfig[0].text === 'Final Payments & Deductions') {
        filtered = filtered.filter((item) => item.text !== 'Final Payments & Deductions');
      }
      return this.formData.formOne.saved ? filtered : [filtered[0]];
    },
    canPrint() {
          return ["leaver.notifyH2RNewLeaver", "leaver.complete", "leaver.notifyH2RAdminAssigned"].includes(this.formData?.currentStageName)
        },
  },
  methods: {
    handleMakeEditable() {
      this.oldFormData = JSON.parse(JSON.stringify(this.formData));
      this.editable = !this.editable;
      // show notification
      this.$store.dispatch("setNotificationAll", {
        visible: true,
        type: "info",
        title: "Warning!",
        message: "Remember to click \"Next\" at the bottom of each page to save your changes.",
      });
    },
    incrementFormPoint() { this.formPoint++ },
    handleModalOpen() { this.showModal = true },
    handleModalClose() { this.showModal = false },
    handleAssign() {
      this.$router.push(`/assign-leaver-admin/${this.formData.docId}/${this.formData.requestedBy}`);
    },
    handleConfirmLeaver() {
      this.$router.push(`/confirm-leaver/${this.formData.docId}/${this.formData.requestedBy}`);
    },
    handleRespondMoreInfo() {
      this.$router.push(`/more-info/${this.formData.docId}/${this.formData.requestedBy}`);
    },
    handleReassign() {
      const id = this.formData.docId;
      const docOwner = this.formData.requestedBy;
      this.$router.push(`/re-assign/${id}/${docOwner}`)
    },
    handleCompleteRequest() {
      this.$router.push(`/complete-leaver/${this.formData.docId}/${this.formData.requestedBy}`);
    },
    setCurrentNavItem(value) {
      return (this.formPoint = value);
    },
    async handleSubmitForm(event) {
      let changedValues = "";
      // get any values changed by a 2nd line approver so they can be saved in the activity log
      if (this.editable && ['leaver.notifyHROps'].includes(this.formData.currentStageName)) {
        changedValues = getChangedValues(this.oldFormData, this.formData);
        this.oldFormData = JSON.parse(JSON.stringify(this.formData));
      }
      const payload = {
        event,
        requestedBy: this.formData.requestedBy || this.profile?.userID,
        id: this.$route.params.id === 'new-leaver' ? null : this.leaverId || this.$route.params.id,
        stage: this.formData.currentStageName
      }
      if (changedValues.length) {
        payload.changes = JSON.stringify(changedValues);
        payload.edited = true;
      }
      const res = await this.$store.dispatch("saveLeaverFormStep", payload);
      if (res && this.formPoint < this.navItemConfig.length - 1) {
        this.formPoint++;
      }
      this.$store.dispatch("updateLeaverStore");
      this.formData = this.$store.getters.getLeaver
    },
    async handleSubmitLeaver() {
      try {
        this.$store.dispatch('setIsLoading', true);
        const id = this.$router.currentRoute.value.params.id;
        const requestedBy = this.formData.requestedBy || this.profile?.userID;
        await httpClient.post('/bff/leaver/complete', { id, requestedBy });

        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: "Success!",
          message: "The leaver form has been successfully submitted."
        });
        this.$router.push('/');
      } catch (err) {
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: "Error!",
          message: "There was a problem submitting the form."
        });
      }
    },
    async handleCancelLeaver() {
      try{
        this.$store.dispatch('setIsLoading', true);
        const id = this.$router.currentRoute.value.params.id;
        const requestedBy = this.profile?.userID;
        const docOwner = this.formData.requestedBy;
        await httpClient.post('/bff/cancel-request', { id, requestedBy, docOwner });
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: "Success!",
          message: "This leaver request has been cancelled."
        });
        this.$store.dispatch('setIsLoading', false);
        this.$router.push('/');
      } catch(err) {
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: "Error!",
          message: "There was a problem cancelling the leaver request."
        });
      }
    },
    async handleDeleteDraft() {
      try{
        this.$store.dispatch('setIsLoading', true);
        const id = this.$router.currentRoute.value.params.id;
        const requestedBy = this.profile?.userID;
        const docOwner = this.formData.requestedBy;
        await httpClient.post('/bff/delete-draft', { id, requestedBy, docOwner });
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'success',
          title: "Success!",
          message: "This leaver request has been deleted."
        });
        this.$store.dispatch('setIsLoading', false);
        this.$router.push('/');
      } catch(err) {
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch('setNotificationAll', {
          visible: true,
          type: 'error',
          title: "Error!",
          message: "There was a problem deleting the leaver request."
        });
      }
    },
    async handleHROpsApproval() {
      this.$store.dispatch('setIsLoading', true);
      const payload = {
        approvalComment: 'Approved by HROps',
        approved: 'TRUE',
        stage: this.formData.currentStageName,
        id: this.formData.docId,
        requestedBy: this.profile?.email,
        docOwner: this.formData.requestedBy
      };
      await httpClient.post('/bff/action-request', payload);
      this.$router.push('/');
      this.$store.dispatch('setIsLoading', false);
    },
    async handleRejectLeaver() {
      this.$store.dispatch('setIsLoading', true);
      const payload = {
        approvalComment: 'Rejected by HROps',
        approved: 'FALSE',
        stage: this.formData.currentStageName,
        id: this.formData.docId,
        requestedBy: this.profile?.email,
        docOwner: this.formData.requestedBy
      };
      await httpClient.post('/bff/action-request', payload);
      this.$router.push('/');
      this.$store.dispatch('setIsLoading', false);

    },
    // function to fetch an atr and save it to local storage
    async fetchSingleDocument(id) {
      this.$store.dispatch('setIsLoading', true);
      const requestedBy = this.$route.params.docOwner;
      const payload = { requestedBy, id }
      const response = await httpClient.post('/bff/get-document', payload);
      const body = response.data.body;
      this.formData = body.Item;
      saveStorage('leaverForm', JSON.stringify(body.Item));
      saveStorage('leaverShortId', body.Item.shortId);
      saveStorage('leaverId', body.Item.docId);
      this.$store.dispatch('setIsLoading', false);
    },
    // Fetch config data
    async fetchConfigData() {
      const response = await httpClient.post('/config/all', { requestedBy: 'something' });
      this.configItems = response.data.body;
    },
    print() {
      if (this.inPrintMode) {
        return;
      }
      this.inPrintMode = true;
      setTimeout(() => {
        window.print()
        setTimeout(() => {
          this.inPrintMode = false;
        }, 0);
      }, 1000);
    },
  },
  async created() {
    this.$store.dispatch('setIsLoading', true);
    if (this.$route.name === 'View leaver') {
      const id = this.$route.params.id;
      await this.fetchSingleDocument(id);
      this.$store.dispatch('updateLeaverStore');
      this.formData = this.$store.getters.getLeaver
    } else {
      // get cached formData if exists
      const cachedFormData = getStorage('leaverForm');
      if (cachedFormData) this.formData = JSON.parse(cachedFormData);
    }
    await this.fetchConfigData();
    if (this.navItems[0].text !== 'Final Payments & Deductions') {
      this.incrementFormPoint();
    }
    this.$store.dispatch('setIsLoading', false);
  },
  mounted() {
    const id = this.$router.currentRoute.value.params.id;
    if(id != 'new-leaver') {
      this.leaverForm = getStorage("leaverForm");
      if (this.leaverForm) {
        const cachedId = JSON.parse(this.leaverForm).leaverId ?? "";
        if (id === cachedId) {
          this.$store.dispatch("updateLeaverStore");
          this.formData = this.$store.getters.getLeaver
        }
      }
    }
  },
};
</script>
