<template>
  <div>
    <form-layout
      :navItemConfig="navItems"
      :currentNavItem="currentNavItem"
      :formPoint="formPoint"
      :formData="amendsFormData"
      :displayHeader="disableForm || editable"
      :displayTimeline="disableForm || editable"
      :displayFooter="disableForm || editable"
      :printMode="inPrintMode"
      formName="amendForm"
      @set-current-nav-item="setCurrentNavItem"
    >
      <template #pageTitle>Employee Amends</template>
      <template #header>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Name of employee:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ amendsFormData.formOne.name }}
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Stage:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ currentStage?.displayName || "-" }}
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Current Assignee:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ amendsFormData.currentAssigneeEmail }}
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Status:</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ amendsFormData.status }}
          </dd>
        </div>
        <div
          class="flex justify-end"
          v-if="profile?.ADMIN || profile?.H2R || profile?.RESOURCING"
        >
          <button
            type="button"
            class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            @click="handleReassign"
          >
            Re-assign
          </button>
        </div>
      </template>
      <template #pageSubtitle>
        <b
          >*Please ensure that all mandatory fields are completed before
          submitting for approval</b
        ><br />
        <b
          >*For compatible browsers please review this link. If your browser is
          not compatible it may effect the text you see within multi line text
          boxes and /or the speed of the people search.</b
        >
      </template>
      <template #main>
        <component
          :is="currentNavItem?.component"
          :formData="amendsFormData[currentNavItem?.name]"
          :data="amendsFormData"
          :disableForm="disableForm"
          :configItems="configItems"
          :canComplete="canComplete"
          @submit-form="handleSubmitForm"
          @complete-amend="handleCompleteAmend"
          @update:formData="handleUpdateFormData"
        />
      </template>
      <template #printArea>
        <div v-for="comp in navItemConfig" v-bind:key="comp.name">
          <component
            :is="comp?.component"
            :formData="amendsFormData[comp?.name]"
            :data="amendsFormData"
            :disableForm="true"
            :configItems="configItems"
            :canComplete="canComplete"
          />
        </div>
      </template>
      <template #navFooter>
        <div
          v-if="
            currentUserIsDirectlyAssigned &&
            (disableForm || editable) &&
            amendsFormData.currentStageName === 'amend.notifyBudgetApprover'
          "
        >
          <button
            class="w-full text-center px-4 py-2 mb-2 rounded-md text-white bg-blue-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
            v-if="!editable"
            :class="{ 'bg-red-700 border-red-900': editable }"
            @click="handleMakeEditable"
          >
            Edit
          </button>
          <button
            class="w-full text-center px-4 py-2 rounded-md text-white bg-green-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
            @click="handleApprove"
          >
            {{ editable ? "Save Edits" : "Approve" }}
          </button>
        </div>

        <button
          v-if="amendsFormData?.currentStageName === 'DRAFT'"
          class="w-full mt-2 text-center px-4 py-2 rounded-md text-white bg-red-700 hover:bg-red-800 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          @click="showDeleteModal = true"
        >
          Delete Draft
        </button>

        <button
          v-if="
            currentUserIsAssigned &&
            disableForm &&
            amendsFormData.currentStageName === 'amend.notifyAmendH2R'
          "
          class="w-full text-center px-4 py-2 rounded-md text-white bg-green-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          @click="handleAssignAdmin"
        >
          Assign Admin
        </button>
        <button
          v-if="canPrint"
          class="w-full text-center mt-2 px-4 py-2 rounded-md text-white bg-blue-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          @click="print"
        >
          Print/Save to PDF
        </button>
        <div
          v-if="
            currentUserIsDirectlyAssigned &&
            (disableForm || editable) &&
            amendsFormData.currentStageName === 'amend.notifyHROps'
          "
        >
          <button
            class="w-full text-center px-4 py-2 mb-2 rounded-md text-white bg-blue-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
            v-if="!editable"
            :class="{ 'bg-red-700 border-red-900': editable }"
            @click="handleMakeEditable"
          >
            Edit
          </button>
          <button
            class="w-full text-center px-4 py-2 rounded-md text-white bg-green-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
            @click="handleApprove"
          >
            {{ editable ? "Save Edits" : "Approve Second line" }}
          </button>
        </div>

        <button
          v-if="
            currentUserIsAssigned &&
            amendsFormData.currentStageName ===
              'amend.notifyAmendH2RAdminUpdated'
          "
          class="w-full text-center px-4 py-2 rounded-md text-white bg-green-700 hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          @click="handleConfirmAmend"
        >
          Admin Approve Or More Info
        </button>
        <button
          v-if="
            currentUserIsDirectlyAssigned &&
            amendsFormData.currentStageName === 'amend.notifyHMMoreInfo'
          "
          class="w-full text-center px-4 py-2 rounded-md text-white bg-green-700 hover:bg-green-800 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          @click="handleRespondMoreInfo"
        >
          Reply to more info request
        </button>
        <button
          v-if="
            currentUserIsAssigned &&
            disableForm &&
            amendsFormData.currentStageName === 'amend.notifyH2RUpdateAmend'
          "
          class="w-full text-center px-4 py-2 rounded-md text-white bg-green-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          @click="handleCompleteRequest"
        >
          Complete request
        </button>

        <button
          v-if="
            amendsFormData.currentStageName &&
            amendsFormData.currentStageName !== 'DRAFT' &&
            (profile?.ADMIN ||
              profile?.H2R ||
              profile?.RESOURCING ||
              currentUserIsAssigned) &&
            amendsFormData.currentStageName !== 'amend.notifyH2RUpdateAmend' &&
            amendsFormData.currentStageName !== 'amend.complete'
          "
          class="w-full mt-2 text-center px-4 py-2 rounded-md text-white bg-red-700 hover:bg-red-800 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          @click="handleCancelAmend"
        >
          Cancel Amend
        </button>
        <button
          v-if="
            (currentUserIsDirectlyAssigned || profile.H2R || profile.ADMIN) &&
            ['amend.notifyBudgetApprover', 'amend.notifyHROps'].includes(
              amendsFormData.currentStageName
            ) &&
            !isDraft
          "
          class="mt-2 w-full text-center px-4 py-2 rounded-md text-white bg-red-700 hover:bg-red-800 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          @click="openRejectModal"
        >
          Reject
        </button>
        <p
          v-if="
            amendsFormData.currentStageName === 'amend.notifyBudgetApprover' ||
            amendsFormData.currentStageName === 'amend.notifyHROps'
          "
          class="italic text-gray-800 mt-2"
        >
          * If you would like to request more info, scroll down and use the form
          at the bottom of the page
        </p>
      </template>
      <template #timeline>
        <activity-feed
          v-if="amendsFormData.activityList"
          :activityList="amendsFormData?.activityList"
        ></activity-feed>
      </template>
      <template #footer>
        <div class="rounded-lg p-4 w-full">
          <approval-steps
            v-if="amendsFormData.activityList"
            docType="AMEND"
            :formData="amendsFormData"
            @comment="fetchSingleDocument(amendsFormData.docId)"
          ></approval-steps>
        </div>
      </template>
    </form-layout>
    <ModalWindow v-if="showRejectModal" @close="closeRejectModal">
      <template v-slot:icon>
        <ExclamationIcon
          class="h-12 w-12 bg-white text-red-600"
          aria-hidden="true"
        />
      </template>
      <template v-slot:title>Reject Amendment</template>
      <template v-slot:subtitle
        >Are you sure you want to reject this amendment? This action cannot be
        undone.</template
      >
      <template v-slot:main>
        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
        >
          <label for="comments" class="block text-sm font-medium text-gray-700"
            >Rejection Comments</label
          >
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <textarea
              id="about"
              name="about"
              v-model="rejectionComment"
              rows="3"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
            />
          </div></div
      ></template>
      <template v-slot:footer>
        <button
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 my-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          @click="handleRejectRequest"
        >
          Reject
        </button>
        <button
          type="button"
          class="mt-2 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 my-2 bg-gray-200 hover:bg-gray-300 text-base font-medium text-gray-800 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          @click="closeRejectModal"
        >
          Cancel
        </button>
      </template>
    </ModalWindow>
    <ModalWindow v-if="showDeleteModal" @close="showDeleteModal = false">
      <template v-slot:icon>
        <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
      </template>
      <template v-slot:title>Delete Draft</template>
      <template v-slot:subtitle
        >Are you sure you want to delete this draft?</template
      >
      <template v-slot:footer>
        <button
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 my-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          @click="handleDeleteDraft"
        >
          Delete
        </button>
        <button
          class="w-full text-center mt-2 px-4 py-2 rounded-md text-white bg-red-700 hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 bg-no-repeat"
          @click="showDeleteModal = false"
        >
          Cancel
        </button>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import { shallowRef } from "vue";
import { getChangedValues } from "@/formFunctions";
import { getStorage, saveStorage } from "@/storageSave";
import { ExclamationIcon } from "@heroicons/vue/solid";
import activityFeed from "@/components/activityFeed.vue";
import AmendsApprovals from "./approvalWorkflowForms/amendsApprovals.vue";
import approvalSteps from "@/components/create-starter/approvalSteps.vue";
import httpClient from "@/httpClient";
import formLayout from "@/layouts/formLayout.vue";
import formOne from "@/components/create-amend/formOne.vue";
import formtwo from "@/components/create-amend/formTwo.vue";
import formThree from "@/components/create-amend/formThree.vue";
import formFourteen from "@/components/create-amend/formFourteen.vue";
import ModalWindow from "@/components/ModalWindow.vue";
import nextSteps from "@/components/create-amend/nextSteps.vue";
import { CheckIcon } from "@heroicons/vue/outline";

export default {
  name: "CreateAmend",
  inject: ["stages"],
  components: {
    activityFeed,
    approvalSteps,
    ExclamationIcon,
    formLayout,
    ModalWindow,
    CheckIcon,
  },
  data() {
    return {
      rejectionComment: "",
      showDeleteModal: false,
      formPoint: 0,
      leaverId: 0,
      cachedForm: null,
      configItems: {},
      showRejectModal: false,
      inPrintMode: false,
      navItemConfig: shallowRef([
        {
          formPoint: 0,
          name: "formOne",
          text: "Your Details",
          component: formOne,
          icon: "UserCircleIcon",
        },
        {
          formPoint: 1,
          name: "formTwo",
          text: "Employee Details",
          component: formtwo,
          icon: "UserIcon",
        },
        {
          formPoint: 2,
          name: "formThree",
          text: "Change Details",
          component: formThree,
          icon: "UserIcon",
        },
        {
          formPoint: 3,
          name: "formFourteen",
          text: "Attachments",
          component: formFourteen,
          icon: "UserIcon",
        },
        {
          formPoint: 4,
          name: "formFifteen",
          text: "Next Steps",
          component: nextSteps,
          icon: "UserIcon",
        },
        {
          formPoint: 5,
          name: "formFifteen",
          text: "First & Second Line Approval",
          component: AmendsApprovals,
          icon: "UserIcon",
        },
      ]),
      editable: false,
      oldFormData: {},
      amendsFormData: {
        docId: null,
        shortId: null,
        formOne: {
          name: "",
          email: "",
          phone: "",
          secondaryContact: "",
          secondaryName: "",
          secondaryEmail: "",
          secondaryPhone: "",
          saved: false,
        },
        formTwo: {
          itAccount: "",
          employeeName: "",
          employeeNumber: "",
          division: "",
          company_code: "",
          sector_code: "",
          saved: false,
        },
        formThree: {
          effectiveDate: "",
          amendmentType: [],
          retainTC: [],
          moveTC: [],
          roleUpdate: [],
          confirmation: [],
          pattern: {
            monday: {
              start: "",
              end: "",
              break: "",
            },
            tuesday: {
              start: "",
              end: "",
              break: "",
            },
            wednesday: {
              start: "",
              end: "",
              break: "",
            },
            thursday: {
              start: "",
              end: "",
              break: "",
            },
            friday: {
              start: "",
              end: "",
              break: "",
            },
            saturday: {
              start: "",
              end: "",
              break: "",
            },
            sunday: {
              start: "",
              end: "",
              break: "",
            },
          },
          saved: false,
        },
        formFour: {
          new_job_level: "",
          saved: false,
        },
        formFive: {
          entity: "",
          costCenter: "",
          saved: false,
        },
        formSix: {
          contract_type: "",
          contract: "",
          saved: false,
        },
        formSeven: {
          salary_change: "",
          currency: "GBP",
          current_salary: "",
          new_salary: "",
          diff: "",
          saved: false,
        },
        formEight: {
          type_change_1: "",
          new_allowance_type_1: "",
          per_annum_1: "",
          type_change_2: "",
          new_allowance_type_2: "",
          per_annum_2: "",
          type_change_3: "",
          new_allowance_type_3: "",
          per_annum_3: "",
          type_change_4: "",
          new_allowance_type_4: "",
          per_annum_4: "",
          type_change_5: "",
          new_allowance_type_5: "",
          per_annum_5: "",
          role_change: "",
          saved: false,
        },
        formNine: {
          line_manager: "",
          employee_number: "",
          org_name: "",
          saved: false,
        },
        formTen: {
          new_location: "",
          location_postcode: "",
          dwp_site: "",
          hybrid_worker: "",
          saved: false,
        },
        formEleven: {
          entity: "",
          cost_centre: "",
          additonal_details: "",
          employee_seconding: "",
          people_reporting: "",
          people_reporting_now: "",
        },
        formTwelve: {
          new_hours_year: "",
          new_hours_week: "",
          current_salary: "",
          new_salary: "",
          MondayStart: "",
          MondayEnd: "",
          MondayBreaks: "",
          TuesdayStart: "",
          TuesdayEnd: "",
          TuesdayBreaks: "",
          WednesdayStart: "",
          WednesdayEnd: "",
          WednesdayBreaks: "",
          ThursdayStart: "",
          ThursdayEnd: "",
          ThursdayBreaks: "",
          FridayStart: "",
          FridayEnd: "",
          FridayBreaks: "",
          SaturdayStart: "",
          SaturdayEnd: "",
          SaturdayBreaks: "",
          SundayStart: "",
          SundayEnd: "",
          SundayBreaks: "",
        },
        formThirteen: {
          job_title: "",
          job_level: "",
          new_entity: "",
          secondment: "",
          promotion: "",
          retain_list: [],
          move_engie: [],
          benifits_add: "",
          benifits_remove: "",
          reporting_to: "",
          reporting_to_now: "",
          overtime_rates: "",
          role_change: "",
          role_update: "",
          one_hr: "",
        },
        formFourteen: {
          files: [],
          comments: "",
          saved: false,
        },
        formFifteen: {
          firstLineApproverName: "",
          firstLineApproverEmail: "",
          saved: false,
        },
      },
    };
  },
  computed: {
    currentNavItem() {
      return this.navItemConfig.find(
        (item) => item.formPoint === this.formPoint
      );
    },
    profile() {
      return this.$store.getters.getProfile;
    },
    disableForm() {
      if (this.editable) {
        return false;
      }
      return (
        (this.amendsFormData?.status !== undefined &&
          this.amendsFormData?.status !== "DRAFT") ||
        this.currentNavItem?.text === "First & Second Line Approval"
      );
    },
    currentUserIsAssigned() {
      return this.profile.H2R;
    },
    currentUserIsDirectlyAssigned() {
      return (
        this.amendsFormData?.currentAssignee?.toUpperCase() ===
        this.profile?.userID.toUpperCase()
      );
    },
    currentStage() {
      return this.stages.find(
        (stage) => this.amendsFormData.currentStageName === stage.stageName
      );
    },
    navItems() {
      let navItems = this.navItemConfig;
      const index = navItems.findIndex((item) => item?.text === "Next Steps");
      if (navItems[index]) {
        if (
          this.disableForm &&
          navItems[index].text === "Next Steps" &&
          this.currentNavItem?.text !== "First & Second Line Approval"
        ) {
          navItems.splice(index, 1);
        }
      }
      return this.amendsFormData?.formOne.saved ? navItems : [navItems[0]];
    },
    canComplete() {
      return (
        this.amendsFormData.formOne.saved &&
        this.amendsFormData.formTwo.saved &&
        this.amendsFormData.formThree.saved &&
        this.amendsFormData.formFourteen.saved &&
        this.amendsFormData.formFifteen.saved
      );
    },
    isDraft() {
      if (this.amendsFormData.status) {
        if (this.amendsFormData.status === "DRAFT") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    canPrint() {
      return [
        "amend.notifyAmendH2R",
        "amend.complete",
        "amend.notifyAmendH2RAdminUpdated",
      ].includes(this.amendsFormData.currentStageName);
    },
  },
  methods: {
    handleMakeEditable() {
      this.oldFormData = JSON.parse(JSON.stringify(this.amendsFormData));
      this.editable = !this.editable;
      // show notification
      this.$store.dispatch("setNotificationAll", {
        visible: true,
        type: "info",
        title: "Warning!",
        message: "Remember to click \"Next\" at the bottom of each page to save your changes.",
      });
    },
    handleConfirmAmend() {
      this.$router.push(
        `/confirm-amend/${this.amendsFormData.docId}/${this.amendsFormData.requestedBy}`
      );
    },
    handleRespondMoreInfo() {
      this.$router.push(
        `/more-info/${this.amendsFormData.docId}/${this.amendsFormData.requestedBy}`
      );
    },
    // Fetch config data
    async fetchConfigData() {
      const response = await httpClient.post("/config/all", {
        requestedBy: "something",
      });
      this.configItems = response.data.body;
    },
    setCurrentNavItem(value) {
      return (this.formPoint = value);
    },
    async handleSubmitForm({ name, formData }) {
      try {
        this.$store.dispatch("setIsLoading", true);
        let changedValues = "";
        // get any values changed by a 2nd line approver so they can be saved in the activity log
        if (
          this.editable &&
          ["amend.notifyHROps", "amend.notifyBudgetApprover"].includes(
            this.amendsFormData.currentStageName
          )
        ) {
          changedValues = getChangedValues(
            this.oldFormData,
            this.amendsFormData
          );
          this.oldFormData = JSON.parse(JSON.stringify(this.amendsFormData));
        }

        this.amendsFormData[name].saved = true;
        const payload = {
          formData: { ...formData },
          requestedBy: this.amendsFormData.requestedBy || this.profile?.userID,
          id: this.amendsFormData.docId,
          stage: this.amendsFormData.currentStageName,
        };
        if (changedValues.length) {
          payload.changes = JSON.stringify(changedValues);
          payload.edited = true;
        }
        const res = await httpClient.post(`bff/amend/${name}`, payload);
        this.amendsFormData = res.data.body.Item;
        saveStorage("amendsFormData", JSON.stringify(this.amendsFormData));
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "success",
          title: "SAVED!",
          message: "The form has been saved.",
        });
        if (res && this.formPoint < this.navItemConfig.length - 2) {
          this.formPoint++;
        }
        this.$store.dispatch("setIsLoading", false);
      } catch (err) {
        console.log(err);
        this.amendsFormData[name].saved = false;
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "error",
          title: "Error!",
          message: "There was an error submitting the form",
        });
        this.$store.dispatch("setIsLoading", false);
      }
    },
    handleReassign() {
      const id = this.amendsFormData.docId;
      const docOwner = this.amendsFormData.requestedBy;
      this.$router.push(`/re-assign/${id}/${docOwner}`);
    },
    handleUpdateFormData({ formName, formData }) {
      this.amendsFormData[formName] = formData;
      saveStorage("formData", JSON.stringify(this.amendsFormData));
    },
    async handleCompleteAmend() {
      try {
        this.$store.dispatch("setIsLoading", true);
        const payload = {
          requestedBy: this.amendsFormData.requestedBy || this.profile?.userID,
          id: this.amendsFormData.docId,
        };
        await httpClient.post("bff/amend/complete", payload);
        this.$store.dispatch("setIsLoading", false);
        this.$router.push("/");
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "success",
          title: "Success!",
          message: "Successfully completed amendment request",
        });
      } catch (err) {
        this.$store.dispatch("setIsLoading", false);
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "error",
          title: "Error!",
          message:
            "There was an error whilst trying to complete the amendment request",
        });
      }
    },
    // function to fetch an atr and save it to local storage
    async fetchSingleDocument(id) {
      this.$store.dispatch("setIsLoading", true);
      const requestedBy = this.$route.params.docOwner;
      const payload = { requestedBy, id };
      const response = await httpClient.post("/bff/get-document", payload);
      const body = response.data.body;
      this.amendsFormData = body.Item;
      saveStorage("amendsFormData", JSON.stringify(body.Item));
      this.$store.dispatch("setIsLoading", false);
    },
    handleApprove() {
      this.$router.push(
        `/approve-amend/${this.amendsFormData.docId}/${this.amendsFormData.requestedBy}`
      );
    },
    handleAssignAdmin() {
      this.$router.push(
        `/admin-assign-amend/${this.amendsFormData.docId}/${this.amendsFormData.requestedBy}`
      );
    },
    handleCompleteRequest() {
      this.$router.push(
        `/complete-amend/${this.amendsFormData.docId}/${this.amendsFormData.requestedBy}`
      );
    },
    openRejectModal() {
      this.showRejectModal = true;
    },
    closeRejectModal() {
      this.showRejectModal = false;
    },

    async handleCancelAmend() {
      try {
        this.$store.dispatch("setIsLoading", true);
        const id = this.amendsFormData.docId
          ? this.amendsFormData.docId
          : this.$router.currentRoute.value.params.id;
        const requestedBy = this.profile?.userID;
        const docOwner = this.amendsFormData.requestedBy;
        await httpClient.post("/bff/cancel-request", {
          id,
          requestedBy,
          docOwner,
        });
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "success",
          title: "Success!",
          message: "This ammendment request has been cancelled.",
        });
        this.$store.dispatch("setIsLoading", false);
        this.$router.push("/");
      } catch (err) {
        this.$store.dispatch("setIsLoading", false);
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "error",
          title: "Error!",
          message: "There was a problem cancelling the ammendment request.",
        });
      }
    },
    async handleDeleteDraft() {
      try {
        this.$store.dispatch("setIsLoading", true);
        const id = this.amendsFormData.docId
          ? this.amendsFormData.docId
          : this.$router.currentRoute.value.params.id;
        const requestedBy = this.profile?.userID;
        const docOwner = this.amendsFormData.requestedBy;
        await httpClient.post("/bff/delete-draft", {
          id,
          requestedBy,
          docOwner,
        });
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "success",
          title: "Success!",
          message: "This amendment has been deleted.",
        });
        this.$store.dispatch("setIsLoading", false);
        this.$router.push("/");
      } catch (err) {
        this.$store.dispatch("setIsLoading", false);
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "error",
          title: "Error!",
          message: "There was a problem deleting the amendment.",
        });
      }
    },
    async handleRejectRequest() {
      this.$store.dispatch("setIsLoading", true);
      try {
        await httpClient.post("/bff/reject-request", {
          docOwner: this.amendsFormData.requestedBy,
          id: this.amendsFormData.docId,
          state: this.amendsFormData.currentStageName,
          comment: this.rejectionComment,
        });
        this.$router.push("/");
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "success",
          title: "Success!",
          message: "Amendment was successfully rejected",
        });
      } catch (err) {
        console.log(err);
        this.$store.dispatch("setNotificationAll", {
          visible: true,
          type: "error",
          title: "Error!",
          message: "Could not reject at this time",
        });
      }

      this.$store.dispatch("setIsLoading", false);
    },
    print() {
      if (this.inPrintMode) {
        return;
      }
      this.inPrintMode = true;
      setTimeout(() => {
        window.print();
        setTimeout(() => {
          this.inPrintMode = false;
        }, 0);
      }, 1000);
    },
  },
  async created() {
    await this.fetchConfigData();
    if (this.$route.name === "View Amend") {
      const id = this.$route.params.id;
      await this.fetchSingleDocument(id);
    } else {
      // get cached formData if exists
      const cachedForm = getStorage("amendsFormData");
      if (cachedForm) this.amendsFormData = JSON.parse(cachedForm);
    }
  },
};
</script>
