<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Organisation Details
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <div>
          <div class="grid grid-cols-3 gap-6">
            <div class="col-span-6">
              <label
                for="businesscontact"
                class="block text-sm font-medium text-gray-700"
                >What is the name of the Business Contract that this role will
                be working on? *</label
              >
              <span
                v-if="v$.formData.businesscontact.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.businesscontact.$errors[0].$message }}
              </span>
              <input
                type="text"
                name="businesscontact"
                id="businesscontact"
                :value="formData.businesscontact"
                :disabled="showApprovalSteps"
                autocomplete="businesscontact"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-700"
                :class="{
                  'error-border': v$.formData.businesscontact.$error,
                  'border-red-500': v$.formData.businesscontact.$error,
                }"
                @input="handleInputChange"
                @blur="v$.formData.businesscontact.$touch()"
              />
            </div>
          </div>
          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <div class="col-span-6">
              <label
                for="employedin"
                class="block text-sm font-medium text-gray-700"
                >Which employing entity will this role be employed in? *</label
              >
              <span
                v-if="v$.formData.employedin.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.employedin.$errors[0].$message }}
              </span>
              <select
                id="employedin"
                name="employedin"
                autocomplete="employed-in"
                :value="formData.employedin"
                :disabled="showApprovalSteps"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-700"
                :class="{
                  'error-border': v$.formData.employedin.$error,
                  'border-red-500': v$.formData.employedin.$error,
                }"
                @change="handleInputChange"
                @blur="v$.formData.employedin.$touch()"
              >
                <template v-if="showApprovalSteps">
                  <option
                    v-for="entity in configData.ENTITIES"
                    :key="entity.entity"
                    :value="entity.entity"
                  >
                    {{ entity.entity }}
                  </option>
                </template>
                <template v-else>
                  <option
                    v-for="entity in activeEntities"
                    :key="entity.entity"
                    :value="entity.entity"
                  >
                    {{ entity.entity }}
                  </option>
                </template>
              </select>
              <p class="mt-1 text-sm text-red-500">
                <b>
                  * The Employing Entity will be the Equans company that Cost
                  Centre or Cost Object (Internal Order/WBS code) relates to.
                  Please check with your local Finance support if you are unsure
                  of this
                </b>
              </p>
            </div>
          </div>
          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <div class="col-span-6">
              <label
                for="division"
                class="block text-sm font-medium text-gray-700"
                >Which division will this role be employed in? *</label
              >
              <span
                v-if="v$.formData.division.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.division.$errors[0].$message }}
              </span>
              <select
                id="division"
                name="division"
                autocomplete="division"
                :value="formData.division"
                :disabled="showApprovalSteps"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-700"
                :class="{
                  'error-border': v$.formData.division.$error,
                  'border-red-500': v$.formData.division.$error,
                }"
                @change="handleInputChange"
                @blur="v$.formData.division.$touch()"
              >
                <template v-if="showApprovalSteps">
                  <option
                    v-for="item in configData?.DIVISIONS"
                    :key="item.ID"
                    :value="item.NAME"
                  >
                    {{ item.NAME }}
                  </option>
                </template>
                <template v-else>
                  <option
                    v-for="item in activeDivisions"
                    :key="item.ID"
                    :value="item.NAME"
                  >
                    {{ item.NAME }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <div class="col-span-6">
              <label
                for="sector"
                class="block text-sm font-medium text-gray-700"
                >Which sector will this role be employed in? *</label
              >
              <span
                v-if="v$.formData.sector.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.sector.$errors[0].$message }}
              </span>
              <select
                id="sector"
                name="sector"
                :value="formData.sector"
                :disabled="showApprovalSteps"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-700"
                :class="{
                  'error-border': v$.formData.sector.$error,
                  'border-red-500': v$.formData.sector.$error,
                }"
                @change="handleInputChange"
                @blur="v$.formData.sector.$touch()"
              >
                <template v-if="showApprovalSteps">
                  <option
                    v-for="item in sectors"
                    :key="item.ID"
                    :value="item.NAME"
                  >
                    {{ item.NAME }}
                  </option>
                </template>
                <template v-else>
                  <option
                    v-for="item in activeSectors"
                    :key="item.ID"
                    :value="item.NAME"
                  >
                    {{ item.NAME }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <div class="col-span-6">
              <label
                for="assigned"
                class="block text-sm font-medium text-gray-700"
                >Which cost centre will this role be assigned to? *</label
              >
              <span
                v-if="v$.formData.assigned.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.assigned.$errors[0].$message }}
              </span>
              <select
                id="assigned"
                name="assigned"
                autocomplete="assigned"
                :value="formData.assigned"
                :disabled="showApprovalSteps"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-700"
                :class="{
                  'error-border': v$.formData.assigned.$error,
                  'border-red-500': v$.formData.assigned.$error,
                }"
                @change="handleInputChange"
                @blur="v$.formData.assigned.$touch()"
              >
                <option
                  v-for="cc in costCentres"
                  :key="cc.CostCentre"
                  :value="cc.CostCodeLookUp"
                >
                  {{ cc.CostCodeLookUp }}
                </option>
              </select>
              <p class="mt-1 text-sm text-red-500">
                <b>
                  * Please ensure this Cost Code has been validated by your
                  local Finance support before submitting this form
                </b>
              </p>
            </div>
          </div>

          <div class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4">
            <div class="col-span-6">
              <label for="timeMaximo" class="block text-sm font-medium text-gray-700">
                Does time need to be captured in Maximo (CAFM system)? *
              </label>
              <span v-if="v$.formData.timeMaximo.$errors.length" class="text-red-500">
                * {{ v$.formData.timeMaximo.$errors[0].$message }}
              </span>
              <div class="space-y-4">
                <div>
                  <div class="mt-1 flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        value="Yes"
                        id="radio-maximo-yes"
                        name="timeMaximo"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                        "
                        :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                        :disabled="showApprovalSteps"
                        :checked="formData.timeMaximo === 'Yes'"
                        @change="handleRadioChange"
                        @blur="v$.formData.timeMaximo.$touch()"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="timeMaximo" class="text-sm font-medium text-gray-700"
                        >Yes</label
                      >
                    </div>
                  </div>
                  <div class="flex items-start">
                    <div class="h-5 flex items-center">
                      <input
                        value="No"
                        id="radio-maximo-no"
                        name="timeMaximo"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                          rounded
                          cursor-pointer
                        "
                        :class="{ 'bg-gray-200 border-none cursor-not-allowed': showApprovalSteps }"
                        :disabled="showApprovalSteps"
                        :checked="formData.timeMaximo === 'No'"
                        @change="handleRadioChange"
                        @blur="v$.formData.timeMaximo.$touch()"

                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="timeMaximo" class="text-sm font-medium text-gray-700">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="formData?.timeMaximo === 'Yes'"
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <div class="col-span-6">
              <label
                for="activityCode"
                class="block text-sm font-medium text-gray-700"
                >Please select the activity type from the list below.  If you are unsure of the correct 
                  activity type, please confirm with your Finance Business Partner
              </label>
              <select
                @change="handleInputChange"
                :disabled="showApprovalSteps"
                :value="formData.activityCode"
                name="activityCode"
                id="activityCode"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md w-full disabled:bg-gray-200 disabled:cursor-not-allowed"
              >
                <option
                  v-for="{ code, name } in activityCodes"
                  :key="code"
                  :value="code"
                >
                  {{ code }} - {{ name }}
                </option>
              </select>
              <p v-if="!showApprovalSteps" class="mt-2 text-sm font-medium text-gray-700">
                Find out more about Activity Codes
                <a 
                  href="https://equans.sharepoint.com/:v:/r/sites/UKAdminExcellence/Shared%20Documents/Knowledge%20Bank/Managing%20Jobs/Activity%20type%20explained%20(video).mp4?csf=1&web=1&e=fxS67u"
                  class="text-indigo-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  :disabled="showApprovalSteps"
                  >
                  here
                </a>
              </p>
            </div>
          </div>

          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <div class="col-span-6">
              <label
                for="costObject"
                class="block text-sm font-medium text-gray-700"
                >If required, which Cost Object (WBS code or Internal Order
                Number) will this role be assigned too?</label
              >
              <input
                type="text"
                name="costObject"
                id="costObject"
                :value="formData.costObject"
                :disabled="showApprovalSteps"
                autocomplete="costObject"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-700"
                @input="handleInputChange"
              />
            </div>
          </div>
          <div
            class="grid grid-cols-3 gap-6 sm:border-t sm:border-gray-200 sm:pt-5 mt-4"
          >
            <div class="col-span-6">
              <label
                for="category"
                class="block text-sm font-medium text-gray-700"
                >Which employee category will this role be assigned to? *</label
              >
              <span
                v-if="v$.formData.category.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.category.$errors[0].$message }}
              </span>
              <select
                id="category"
                name="category"
                :value="formData.category"
                :disabled="showApprovalSteps"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-700"
                :class="{
                  'error-border': v$.formData.category.$error,
                  'border-red-500': v$.formData.category.$error,
                }"
                @change="handleInputChange"
                @blur="v$.formData.category.$touch()"
              >
                <option
                  v-for="category in categories"
                  :key="category"
                  :value="category"
                >
                  {{ category }}
                </option>
              </select>
            </div>

            <div class="col-span-6">
              <label
                for="jobFamily"
                class="block text-sm font-medium text-gray-700"
                >Which job family will this role be assigned to? *</label
              >
              <span
                v-if="v$.formData.jobFamily.$errors.length"
                class="text-red-500"
              >
                * {{ v$.formData.jobFamily.$errors[0].$message }}
              </span>
              <select
                id="jobFamily"
                name="jobFamily"
                :value="formData.jobFamily"
                :disabled="showApprovalSteps"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-700"
                :class="{
                  'error-border': v$.formData.jobFamily.$error,
                  'border-red-500': v$.formData.jobFamily.$error,
                }"
                @change="handleInputChange"
                @blur="v$.formData.jobFamily.$touch()"
              >
                <option
                  v-for="family in jobFamilies"
                  :key="family"
                  :value="family"
                >
                  {{ family }}
                </option>
              </select>
            </div>

          </div>
          <!-- form end -->
        </div>
      </div>
      <!-- save button -->
      <div
        v-if="!showApprovalSteps"
        class="px-4 py-3 bg-gray-50 text-right sm:px-6"
      >
        <button
          class="mr-2 bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          @click.prevent="handleResetForm"
        >
          Reset Form
        </button>
        <button
          :disabled="v$.$invalid"
          type="submit"
          class="bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400"
          @click="handleSave"
        >
          Next
        </button>
      </div>
      <!-- end save button -->
    </div>
  </form>
</template>

<script>
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { activityCodes } from "./../../resources/activity-codes";

export default {
  name: "FormFour",
  props: ["formData", "configData", "showApprovalSteps"],
  data() {
    return {
      activityCodes: activityCodes,
      v$: useVuelidate(),
      store: useStore(),
      model: this.formData,
      categories: [
        "Engineers",
        "Profit centre owners",
        "Project managers",
        "Sales",
        "Support function",
        "Others",
      ],
      jobFamilies: [
        "Assistance",
        "Business Development, Sales Support and Marketing",
        "Communication",
        "Construction and Installation",
        "Engineering",
        "Facilities Management",
        "Finance, Taxation and Insurance",
        "General Services and Real Estate",
        "Human Resources",
        "IT, Digital, Data and Cybersecurity (delivering IT to our clients)",
        "IT, Information Systems and Internal Cybersecurity",
        "Legal, Ethics and Compliance",
        "Maintenance",
        "Procurement and Purchasing",
        "Profit Centre Management",
        "Project Management",
        "Quality, Health, Safety, Security and Environment",
        "Research and Development",
        "Strategy, Audit and Internal Control"
      ],
    };
  },
  methods: {
    handleRadioChange(event) {
      const { name, value } = event.target;
      this.model[name] = value;
      this.$emit("update:formData", {
        formName: "formFour",
        formData: this.model,
      });
    },
    handleSave(event) {
      event.preventDefault();
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submit-form", {
          name: "formFour",
          formData: this.formData,
        });
      } else {
        this.store.dispatch("setMessage", {
          type: "error",
          title: "ERROR!",
          message: "Please fill out all the required fields",
        });
      }
    },
    handleInputChange(event) {
      const { name, value } = event.currentTarget;
      this.model[name] = value;
      this.$emit("update:formData", {
        formName: "formFour",
        formData: this.model,
      });
    },
    // emit an event to reset the form
    handleResetForm() {
      this.$emit("update:reset", "formFour");
    },
  },
  computed: {
    costCentres() {
      const entity = this.configData.ENTITIES.find(
        (entity) => entity.entity === this.formData.employedin
      );

      if (entity) {
        return entity.Items.sort((a, b) => {
          const idA = a.CostCentre.toUpperCase();
          const idB = b.CostCentre.toUpperCase();
          return idA.localeCompare(idB);
        });
      }
      return [];
    },
    sectors() {
      const division = this.configData.DIVISIONS.find(
        (division) => division.NAME === this.formData.division
      );
      if (division) return division.SECTORS;
      return [];
    },
    activeEntities() {
      return this.configData.ENTITIES.filter((entity) => entity.LKStatus);
    },
    activeDivisions() {
      return this.configData.DIVISIONS.filter((div) => div.LKStatus);
    },
    activeCostCentres() {
      const entity = this.configData.ENTITIES.find(
        (entity) => entity.entity === this.formData.employedin
      );
      if (entity) return entity.Items?.filter((item) => item.LKStatus);
      return [];
    },
    activeSectors() {
      const division = this.configData.DIVISIONS.find(
        (division) => division.NAME === this.formData.division
      );
      if (division) return division.SECTORS.filter((sector) => sector.LKStatus);
      return [];
    },
  },
  watch: {
    formData(newVal) {
      this.model = newVal;
    },
  },
  validations() {
    return {
      formData: {
        businesscontact: {
          required,
        },
        employedin: {
          required,
        },
        sector: {
          required,
        },
        division: {
          required,
        },
        assigned: {
          required,
        },
        category: {
          required,
        },
        jobFamily: {
          required,
        },
        timeMaximo: {
          required,
        },
      },
    };
  },
};
</script>

<style scoped>
.error-border {
  border: 2px solid red;
}
</style>
