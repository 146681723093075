<template>
  <form action="#" method="POST">
    <div class="shadow sm:rounded-t-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <!-- title header -->
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            First & Second Line Approval
          </h3>
        </div>
        <!-- end title header -->
        <!-- form -->
        <div class="mt-6">
          <label 
            for="first_line"
            class="block text-sm font-medium text-gray-700"
          >Who provided first line approval for this ATR?</label>
          <input
            :value="props.data?.starter_notifyBudgetApproverApproval?.approverEmail || props.data?.atr?.starter_notifyBudgetApproverApproval?.approverEmail"
            type="text"
            name="first_line"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          >
        </div>

        <p class="text-sm">The first line approver is the business representative who has the authority to approve the budget spend for this role.</p>

        <div class="mt-6">
          <label 
            for="first_date"
            class="block text-sm font-medium text-gray-700"
          >First line approval date:</label>
          <input
            :value="props.data?.starter_notifyBudgetApproverApproval?.approvalDate || props.data?.atr?.starter_notifyBudgetApproverApproval?.approvalDate"
            type="date"
            name="first_date"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          >
        </div>

        <div class="mt-6">
          <label 
            for="approval_comments"
            class="block text-sm font-medium text-gray-700"
          >First line approval comments:</label>
          <textarea
            :value="props.data?.starter_notifyBudgetApproverApproval?.comment || props.data?.atr?.starter_notifyBudgetApproverApproval?.comment"
            rows="3"
            name="approval_comments"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          ></textarea>
        </div>

        <div class="mt-6">
          <label 
            for="approval_comments"
            class="block text-sm font-medium text-gray-700"
          >Who provided second line approval for this ATR?:</label>
          <input
            :value="props.data?.starter_notifyHROpsApproval?.approverEmail || props.data?.atr?.starter_notifyHROpsApproval?.approverEmail"
            type="text"
            name="approval_comments"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          >
        </div>

        <p class="text-sm">The second line approver is the HR professional who will ensure all the correct policies and approvals have
            been adhered to. If further approvals are required, they will co-ordinate this with the business outside the
            system and keep records locally for audit purposes.
        </p>

        <p class="text-sm text-blue-700">Before approving please confirm that the following checks have been carried out:</p>

        <div class="mt-6">
          <label 
            for="second_line_date"
            class="block text-sm font-medium text-gray-700"
          >Second line approval date:</label>
          <input
            :value="props.data?.starter_notifyHROpsApproval?.approvalDate || props.data?.atr?.starter_notifyHROpsApproval?.approvalDate"
            type="date"
            name="second_line_date"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          >
        </div>

        <div class="mt-6">
          <label 
            for="second_line_comments"
            class="block text-sm font-medium text-gray-700"
          >Second line approval comments:</label>
          <textarea
            :value="props.data?.starter_notifyHROpsApproval?.comment || props.data?.atr?.starter_notifyHROpsApproval?.comment"
            rows="3"
            name="second_line_comments"
            class="
              mt-1
              focus:ring-indigo-500
              focus:border-indigo-500
              block
              w-full
              shadow-sm
              sm:text-sm
              border
              border-gray-300
              rounded-md
              disabled:cursor-not-allowed
              disabled:bg-gray-200
              disabled:cursor-not-allowed
            "
            :disabled="disableForm"
          ></textarea>
        </div>
        <!-- End Form -->
      </div>
    </div>
  </form>
</template>

<script setup>
import { defineProps } from "vue"

const props = defineProps(['data', 'disableForm']);

</script>
