<template>
  <div class="m-4 px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">All Requests</h1>
        <p class="mt-2 text-sm text-gray-700">A list of all requests in the system.</p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button v-if="loaded && !profile?.disableCreateStarter" type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto" @click="navigateNewDoc('starter')">Create ATR</button>
        <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto ml-2" @click="navigateNewDoc('amend')">Create Amendment</button>
        <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 hover:bg-green-800 hover:underline px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto ml-2" @click="navigateNewDoc('leaver')">Create Leaver</button>
      </div>
    </div>
    <div class="mt-8 flex flex-col md:flex-row justify-end">
      <div class="mt-2">
        <label for="filter-doctype" class="text-gray-600">Doctype:</label>
        <select
          v-model="doctype"
          name="filter-doctype"
          id="filter-doctype-select"
          class="
            w-full
            mt-1
            focus:ring-indigo-500 focus:border-indigo-500
            block
            shadow-sm
            sm:text-sm
            border-gray-300
            rounded-md
          "
        >
          <option value="STARTER">Starters</option>
          <option value="LEAVER">Leavers</option>
          <option value="AMEND">Amends</option>
          <option value="OFFER">Offers</option>
        </select>
      </div>
      <div class="mt-2 md:ml-2">
        <label for="filter-doctype" class="text-gray-600">Stage:</label>
        <select
          v-model="stage"
          name="filter-stage"
          id="filter-stage-select"
          class="
            w-full
            mt-1
            focus:ring-indigo-500 focus:border-indigo-500
            block
            shadow-sm
            sm:text-sm
            border-gray-300
            rounded-md
          "
        >
          <option value="all">All</option>
          <!-- Starter Options -->
          <option
            v-for="stage in stagesOptions"
            :key="stage.stageName"
            :value="stage.stageName"
          >{{ stage.displayName }}</option>
        </select>
      </div>
    </div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Short ID</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date Received</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Name</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Effective Date</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Hiring Manager</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Assigned to</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date Issued</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Current stage</th>
                </tr>
              </thead>
              <tbody v-if="atrs.length" class="bg-white">
                <tr v-for="(atr, index) in atrs" :key="atr.docId" :class="index % 2 === 0 ? undefined : 'bg-gray-50'">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-blue-600 hover:underline cursor-pointer" @click="navigateToDocument(atr)">{{ atr.shortId }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ atr.adminStartDate?.timeStamp ? formatDate(atr.adminStartDate?.timeStamp, 'DD/MM/yyyy') : '-' }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span v-if="doctype === 'STARTER'">-</span>
                    <span v-if="doctype === 'AMEND'">{{ atr.formTwo?.employeeName }}</span>
                    <span v-if="doctype === 'LEAVER'">{{ atr.formTwo?.employee_name }}</span>
                    <span v-if="doctype === 'OFFER'">{{ atr.candidate?.internal_candidate !== 'Yes' ? 
                      (atr.candidate?.firstName ? atr.candidate?.firstName + ' ' + atr.candidate?.surname : '-') :
                      (atr.candidate?.internalCandidateName || '-') }}</span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span v-if="doctype === 'OFFER'">
                      {{ atr.atr?.formThree?.jobTitle ? atr.atr.formThree.jobTitle : 'No role specified' }}
                    </span>
                    <span v-else>
                      {{ atr.formThree?.jobTitle ? atr.formThree.jobTitle : 'No role specified' }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span v-if="atr.startDetails?.startDateTime">
                      {{ new Date(atr.startDetails?.startDateTime).toLocaleDateString() }}
                    </span>
                    <span v-else-if="atr.formSix?.leaving_date">
                      {{ new Date(atr.formSix?.leaving_date).toLocaleDateString()  }}
                    </span>
                    <span v-else-if="atr.formThree?.effectiveDate">
                      {{ new Date(atr.formThree?.effectiveDate).toLocaleDateString() }}
                    </span>
                    <span v-else>-</span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ atr.requestedByEmail }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ currentAssigneeEmail(atr) }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ atr.offerMadeDate?.timeStamp ? formatDate(atr.offerMadeDate?.timeStamp, 'DD/MM/yyyy') : '-' }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ findStage(atr.currentStageName, atr.status) }}</td>
                </tr>
              </tbody>
            </table>
            <p v-if="loaded && !atrs.length" class="text-gray-400 text-center p-8"> No Requests to show... </p>
          </div>
        </div>
        <div class="flex justify-center md:justify-end mx-1 md:mx-8 my-4 px-4 md:px-8 py-4 shadow rounded ring-1 ring-black ring-opacity-5">
          <button class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-green-700 bg-white hover:bg-gray-50 disabled:opacity-25 disabled:text-gray-600 disabled:cursor-not-allowed" @click="prevPage">Prev</button>
          <button class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-green-700 bg-white hover:bg-gray-50 disabled:opacity-25 disabled:text-gray-600 disabled:cursor-not-allowed" @click="nextPage">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import httpClient from '@/httpClient';
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, watch, computed, inject } from 'vue';
import { clearStorage } from '@/storageSave';
import {pathToDocument} from '../helpers/path-to-document-helper'

const store = useStore();
const router = useRouter();
const route = useRoute();
const atrs = ref([]);
const loaded = ref(false);
const doctype = ref('STARTER');
const stage = ref('all');
const pageNumber = ref(0);
const atEnd = ref(false);
const tokens = ref(['']);
const formatDate = inject('formatDate');

const stages = inject('stages');

const profile = computed(() => store.getters.getProfile);

const navigateNewDoc = (docType) => {
  if (docType.toUpperCase() === 'STARTER') {
    clearStorage('atrShortId');
    clearStorage('atrId');
    clearStorage('formData');
  }
  if (docType.toUpperCase() === 'AMEND') {
    clearStorage('amendsFormData');
  }
  if (docType.toUpperCase() === 'LEAVER') {
    store.dispatch('clearAllLeaverFormData');
    clearStorage('leaverShortId');
    clearStorage('leaverForm');
  }

  const routes = {
    starter: '/create-starter/new-atr',
    amend: '/create-amend',
    leaver: '/create-leaver/new-leaver'
  };
  router.push(routes[docType]);
};


const stagesOptions = computed(() => stages.filter((stage) => stage.type === doctype.value.toLowerCase()));

const findStage = (stageName, status) => {
  let stage;
  const findStage = stages.find((stage) => stage.stageName === stageName);
  if (findStage) return findStage.displayName;
  stage = status === 'DRAFT' ? 'Work in progress' : status;
  return stage;
}

const navigateToDocument = (atr) => {
  router.push({path: pathToDocument(atr, doctype.value)})
}

const fetchAtrs = async (type="next") => {
  let url = '/bff/list-by-doctype';
  const token = type === 'next' ? tokens.value[pageNumber.value] : tokens.value[pageNumber.value - 2]
  const response = await httpClient.post(url, {
    requestedBy: '12345678910',
    docType: doctype.value,
    stage: stage.value,
    next: token ? token : undefined,
  });
  if (type === 'next') {
    pageNumber.value++;
  }
  if (type === 'prev') {
    pageNumber.value--;
  }
  if (!response.data.body.next) {
    atEnd.value = true;
  } else {
    atEnd.value = false;
    tokens.value.push(response.data.body.next);
  }
  atrs.value = response.data.body.Items;
};

const nextPage = async() => {
  if (atEnd.value) return;
  store.dispatch('setIsLoading', true);
  await fetchAtrs();
  store.dispatch('setIsLoading', false);
}

const prevPage = async() => {
  if (pageNumber.value === 1) return;
  store.dispatch('setIsLoading', true);
  await fetchAtrs('prev');
  store.dispatch('setIsLoading', false);
}

const setQueryParams = () => {
  if(route.query['doc-type'] === doctype.value && route.query.stage === stage.value){
    return
  }
  router.push({path: 'all-documents',query: {
    'doc-type': doctype.value,
    'stage': stage.value
  }})
}
const setDropdownsFromQuery = () => {
  const queryParams = route.query
  if(queryParams['doc-type'] === doctype.value && queryParams.stage === stage.value){
    return
  }
  doctype.value = queryParams['doc-type'] ?? doctype.value;
  stage.value = queryParams.stage ?? stage.value;
}

const currentAssigneeEmail = (atr) => atr.currentAssigneeEmail ? 
  atr.currentAssigneeEmail.includes('@') ? 
    atr.currentAssigneeEmail : atr.currentAssignee.includes('@') ? 
      atr.currentAssignee : atr.currentAssigneeEmail 
  : 'Unassigned'

onMounted(async() => {
  store.dispatch('setIsLoading', true);
  setDropdownsFromQuery()
  await fetchAtrs();
  store.dispatch('setIsLoading', false);
  loaded.value = true;
});

watch(doctype, async () => {
  store.dispatch('setIsLoading', true);
  stage.value = 'all';
  pageNumber.value = 0;
  atEnd.value = false;
  tokens.value = [''];
  await fetchAtrs()
  store.dispatch('setIsLoading', false);
});

watch(stage, async () => {
  store.dispatch('setIsLoading', true);
  setQueryParams()
  pageNumber.value = 0;
  atEnd.value = false;
  tokens.value = [''];
  await fetchAtrs()
  store.dispatch('setIsLoading', false);
});
watch(()=>route.query, async (newRoute) => {
  if(newRoute['doc-type'] === doctype.value && newRoute.stage === stage.value){
    return
  }
  store.dispatch('setIsLoading', true);
  setDropdownsFromQuery()
  pageNumber.value = 0;
  atEnd.value = false;
  tokens.value = [''];
  await fetchAtrs()
  store.dispatch('setIsLoading', false);
});

</script>
