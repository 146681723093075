<template>
  <div
    class="
      invisible
      fixed
      top-8
      inset-x-0
      mx-auto
      max-w-md
      z-50
      shadow-black
      border-b-4
    "
    :class="{'bg-green-600 border-green-700': notification.type === 'success','bg-red-700 border-red-900': notification.type === 'error', 'bg-amber-400 border-amber-600': notification.type === 'info'}"
    :style="notification.visible ? 'visibility: visible' : null"
  >
    <div class="flex">
      <div class="flex items-center p-2" :class="{'bg-green-500': notification.type === 'success','bg-red-600': notification.type === 'error', 'bg-amber-400': notification.type === 'info'}">
        <CheckCircleIcon
          v-if="notification.type === 'success'"
          class="h-12 w-12 text-green-400"
          aria-hidden="true"
        />
        <InformationCircleIcon v-if="notification.type === 'info'" class="h-12 w-12 text-white" aria-hidden="true" />
        <ExclamationIcon v-if="notification.type === 'error'" class="h-12 w-12 text-red-400" aria-hidden="true" />
      </div>
      <div class="ml-3 py-4">
        <h3 class="text-sm font-medium text-white">{{ notification.title }}</h3>
        <div class="mt-2 text-sm text-white">
          <p>{{ notification.message }}</p>
        </div>
      </div>
      <div class="flex justify-end grow p-2">
        <XCircleIcon class="h-5 w-5 text-white justify-self-end cursor-pointer" @click="handleClose" />
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon, XCircleIcon, ExclamationIcon, InformationCircleIcon } from '@heroicons/vue/solid'

export default {
  components: { CheckCircleIcon, XCircleIcon, ExclamationIcon, InformationCircleIcon },
  computed: {
    notification() { return this.$store.getters.getNotification }
  },
  methods: {
    handleClose() {
      this.$store.dispatch('closeNotification');
    }
  },
  watch: {
    'notification.visible'(newVal) {
      if (newVal === true) {
        setTimeout(() => this.handleClose(), 8000)
      }
    }
  }
}
</script>